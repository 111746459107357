import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import './credit.css';

function Credit({ bank, isLast }) {
  const { t } = useTranslation();
  const [phoneError, setPhoneError] = useState('');
  const [moneyError, setMoneyError] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [money, setMoney] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [phoneNumberDirty, setPhoneNumberDirty] = useState(false);
  const [moneyDirty, setMoneyDirty] = useState(false);

  const regExpPhone = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,18}(\s*)?$/gm;

  const phoneNumberHandler = (e) => {
    setPhoneNumber(e.target.value);

    if (!e.target.value.match(regExpPhone)) {
      setPhoneError('credit-input-error');
    } else {
      setPhoneError('');
    }
  };

  const moneyHandler = (e) => {
    setMoney(e.target.value);

    if (!e.target.value.match(/^[0-9 ]{1,9}$/gm)) {
      setMoneyError('credit-input-error');
    } else {
      setMoneyError('');
    }
  };

  useEffect(() => {
    if (!phoneError && phoneNumber && money && !moneyError) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [phoneError, phoneNumber, money, moneyError]);

  const blurHandler = (e) => {
    switch (e.target.name) {
      case 'phone':
        setPhoneNumberDirty(true);
        break;
      case 'money':
        setMoneyDirty(true);
        break;
    }
  };

  function sendForm(e) {
    e.preventDefault();

    window.location.href = `mailto:my@tonna.io?subject=credit ${ bank.name || '' }&body=телефон: ${ phoneNumber } сумма: ${ money } ₽`;

    setPhoneNumber('');
    setMoney('');
  }

  return (
    <div className="credit">
      <div className="credit-wrapper">
        <div className="credit-cart-wrapper">
          <div>
            <img src={ bank.image } alt={ bank.name }/>
          </div>
          <p className="credit-percent">
            { bank.percent }
          </p>
          <p className="credit-money">
            { bank.money }
          </p>
          <div>
            <input
              name="checkbox"
              value={ isChecked }
              onClick={ () => setIsChecked(!isChecked) }
              id={ `credit-checkbox-${ bank.name }` }
              className="credit-checkbox"
              type="checkbox"/>
            <label htmlFor={ `credit-checkbox-${ bank.name }` }/>
          </div>
        </div>
        { isLast && <div className="credit-divider"/> }
        <form onSubmit={ sendForm } className={ `credit-form ${ isChecked && 'credit-form-active' }` }>
          <input
            placeholder="Номер телефона"
            className={ `credit-input-phone ${ phoneNumberDirty && phoneError }` }
            value={ phoneNumber }
            onBlur={ blurHandler }
            onInput={ phoneNumberHandler }
            name="phone"
            type="tel"
          />
          <div className="credit-input-money-wrapper">
            <input
              placeholder={ bank.name === 'sberbank' ? '500 000' : '' }
              className={ `credit-input-money ${ moneyDirty && moneyError }` }
              value={ money }
              onBlur={ blurHandler }
              onInput={ moneyHandler }
              name="money"
              type="number"
              min="500000"
              max="100000000"
            />
          </div>
          <button disabled={ disabled } className="btn credit-btn">
            { t('Verification.send') }
          </button>
        </form>
      </div>
    </div>
  );
}

export default Credit;