import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {api} from '../../Store/api'
import {logDOM} from "@testing-library/react";
import {retry} from "@reduxjs/toolkit/query";

export const fetchPublicOffers = createAsyncThunk(
    'offersSlice/fetchPublicOffers',
    async () => {
        const response = await api.get('private/offers');
        return response.data;
    },
)
export const fetchMyOffers = createAsyncThunk(
    'offersSlice/fetchMyOffers',
    async () => {
        const response = await api.get('private/offers/my');
        return response.data;
    },
)
export const fetchMyTrades = createAsyncThunk(
    'offersSlice/fetchMyTrades',
    async () => {
        const response = await api.post('private/trade/my');
        return response.data;
    },
)
export const fetchFiles = createAsyncThunk(
    'offersSlice/fetchFiles',
    async ({id}) => {
        const response = await api.get(`private/files/${id}`)
        return response.data
    }
)
const initialState = () => {
    return {
        offersLoaded: false,
        tradesLoaded: false,
        searchValue: '',
        offers: [],
        files: [],
        file: null,
        error: {
            failed: false,
            message: null,
        },
    }
}

const offersSlice = createSlice({
    name: 'offersSlice',
    initialState: initialState(),
    reducers: {
        filterByPrice(state, action) {
            if (action.payload) {
                state.requests = state.requests.sort((a, b) => a.price > b.price ? 1 : -1)
            } else {
                state.requests = state.requests.sort((a, b) => a.price < b.price ? 1 : -1)
            }
        },
        filterByDate(state, action) {
            if (action.payload) {
                state.requests = state.requests.sort((a, b) => a.date > b.date ? 1 : -1)
            } else {
                state.requests = state.requests.sort((a, b) => a.date < b.date ? 1 : -1)
            }
        },
        searchFilter(state, action) {
            state.searchValue = action.payload
        }
    },
    extraReducers: {
        [fetchPublicOffers.pending]: (state) => {
            state.error.failed = false;
            state.error.message = null;
            state.offersLoaded = false
            state.tradesLoaded = false
        },
        [fetchPublicOffers.fulfilled]: (state, action) => {
            state.requests = action.payload.sort((a, b) => a.id > b.id ? 1 : -1).reverse()
            state.offersLoaded = true
        },
        [fetchPublicOffers.rejected]: (state, action) => {
            // message.error({
            //   content: 'Что-то пошло не так....',
            //   style: {
            //     marginTop: '17vh'
            //   }
            // })
            state.error.message = action.payload;
            state.error.failed = true;
            state.offersLoaded = true
        },
        [fetchMyOffers.pending]: (state) => {
            state.error.failed = false;
            state.error.message = null;
            state.offersLoaded = false
            state.tradesLoaded = false
        },
        [fetchMyOffers.fulfilled]: (state, action) => {
            state.requests = action.payload.reverse()
            state.offersLoaded = true
        },
        [fetchMyOffers.rejected]: (state, action) => {
            // message.error({
            //   content: 'Что-то пошло не так....',
            //   style: {
            //     marginTop: '17vh'
            //   }
            // })
            state.error.message = action.payload;
            state.error.failed = true;
            state.offersLoaded = true
        },
        [fetchMyTrades.pending]: (state) => {
            state.error.failed = false;
            state.error.message = null;
            state.tradesLoaded = false
            state.offersLoaded = false
        },
        [fetchMyTrades.fulfilled]: (state, action) => {
            state.requests = action.payload.reverse()
            state.tradesLoaded = true
        },
        [fetchMyTrades.rejected]: (state, action) => {
            // message.error({
            //   content: 'Что-то пошло не так....',
            //   style: {
            //     marginTop: '17vh'
            //   }
            // })
            state.error.message = action.payload;
            state.error.failed = true;
            state.tradesLoaded = true
        },
        [fetchFiles.pending]: (state) => {
            state.error.failed = false;
            state.error.message = null;
            state.tradesLoaded = false
            state.offersLoaded = false
        },
        [fetchFiles.fulfilled]: (state, action) => {
            state.files = action.payload
            state.tradesLoaded = true
        },
        [fetchFiles.rejected]: (state, action) => {
            // message.error({
            //   content: 'Что-то пошло не так....',
            //   style: {
            //     marginTop: '17vh'
            //   }
            // })
            state.error.message = action.payload;
            state.error.failed = true;
            state.tradesLoaded = true
        },
    }
})

export const {filterByPrice} = offersSlice.actions
export const {filterByDate} = offersSlice.actions
export const {searchFilter} = offersSlice.actions
export default offersSlice.reducer;

