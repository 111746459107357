import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { prepareClassName } from '../../../utils/styles';
import announcementImage from '../../../assets/newLanding/cards/announcement.png';
import secureDealImage from '../../../assets/newLanding/cards/secureDeal.png';
import cloudImage from '../../../assets/newLanding/cards/cloud.png';
import styles from './styles.module.css';

const AnnouncementsAndResponsesCard = () => {
    const className = prepareClassName([styles.card, styles.announcementsAndResponsesCard]);
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className={styles.cardContent}>
                <h2 className={styles.cardTitle}>{t('Lending.AdsAndResponses')}</h2>
                <p className={styles.cardText}>{t('Lending.CreateAds')}</p>
                <Link className={styles.cardButton} to="/newoffer">{t('Lending.createAd')}</Link>
            </div>
            <img className={styles.cardImage} src={announcementImage} alt="" />
        </div>
    );
};

const SecureDealCard = () => {
    const className = prepareClassName([styles.card, styles.secureDealCard]);
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className={styles.cardContent}>
                <h2 className={styles.cardTitle}>{t('Lending.secureTransaction')}</h2>
                <p className={styles.cardText}>{t('Lending.transactionSafely')}</p>
                <Link className={styles.cardButton} to="/newoffer">{t('Lending.startUsing')}</Link>
            </div>
            <img className={styles.cardImage} src={secureDealImage} alt="" />
        </div>
    );
};

const CloudCard = () => {
    const className = prepareClassName([styles.card, styles.cloudCard]);
    const { t } = useTranslation();

    return (
        <div className={className}>
            <div className={styles.cardContent}>
                <h2 className={styles.cardTitle}>{t('Lending.cloudStorage')}</h2>
                <p className={styles.cardText}>{t('Lending.uploadDocuments')}</p>
                <Link className={styles.cardButton} to="/newoffer">{t('Lending.startUsing')}</Link>
            </div>
            <img className={styles.cardImage} src={cloudImage} alt="" />
        </div>
    );
};

const Cards = () => {
    return (
        <section className={styles.wrapper}>
            <AnnouncementsAndResponsesCard />
            <SecureDealCard />
            <CloudCard />
        </section>
    );
};

export default Cards;