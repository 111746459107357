import React from 'react';
import {useTranslation} from "react-i18next";
import GoBack from "./components/GoBack/GoBack";
import {Helmet} from "react-helmet";

function Plan(props) {
    const {t} = useTranslation()
    return (
        <section>
            <div className='content-wrapper'>
                <GoBack prevPageName={t('Verification.goBack')}/>
                <Helmet>
                    <title>{t('Plan.planTitle')}</title>
                </Helmet>
                <h1 style={{marginBottom: 20}} className="title">{t('Plan.planTitle')}</h1><br/>
                <h2 className='plan_subtitle'>{t('Plan.planFirstSubtitle')}</h2><br/><br/>
                <div>
                    <p>{t('Plan.firstParagraph')}</p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.secondParagraphTitle')}</h3><br/>
                        {t('Plan.secondParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirdParagraphTitle')}</h3><br/>
                        {t('Plan.thirdParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.fourthParagraphTitle')}</h3><br/>
                        {t('Plan.fourthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.fifthParagraphTitle')}</h3><br/>
                        {t('Plan.fifthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.sixthParagraphTitle')} </h3><br/>
                        {t('Plan.sixthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.seventhParagraphTitle')}</h3><br/>
                        {t('Plan.seventhParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.eightParagraphTitle')} </h3><br/>
                        {t('Plan.eightParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.ninthParagraphTitle')}</h3><br/>
                        {t('Plan.ninthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.tenthParagraphTitle')}</h3><br/>
                        {t('Plan.tenthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.eleventhParagraphTitle')}</h3><br/>
                        {t('Plan.eleventhParagraphText')}
                    </p><br/>
                </div>
                <h2 className='plan_subtitle'>{t('Plan.planSecondSubtitle')}</h2><br/><br/>
                <div>
                    <span style={{fontSize: '22px', marginBottom: '20px'}}>{t('Plan.twelfthParagraphText')}</span><br/><br/>
                    <h3 style={{fontSize: '22px', marginBottom: '20px'}}>{t('Plan.thirteenParagraphTitle')}</h3><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.fourteenthParagraphTitle')}</h3><br/>
                        {t('Plan.fourteenthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.fifteenthParagraphTitle')}</h3><br/>
                        {t('Plan.fifteenthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.sixteenthParagraphTitle')}</h3><br/>
                        {t('Plan.sixteenthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.seventeenthParagraphTitle')}</h3><br/>
                        {t('Plan.seventeenthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}> {t('Plan.eighteenParagraphTitle')}</h3><br/>
                        {t('Plan.eighteenParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.nineteenParagraphTitle')}</h3><br/>
                        {t('Plan.nineteenParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentiethParagraphTitle')}</h3><br/>
                        {t('Plan.twentiethParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentieFirsthParagraphTitle')}</h3><br/>
                        {t('Plan.twentieFirsthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentieSecondthParagraphTitle')}</h3><br/>
                        {t('Plan.twentieSecondthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentyeThirdthParagraphTitle')}</h3><br/>
                        {t('Plan.twentyeThirdthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentyeFourthParagraphTitle')}</h3><br/>
                        {t('Plan.twentyeFourthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentyeFifthParagraphTitle')}</h3><br/>
                        {t('Plan.twentyeFifthParagraphText')}
                    </p><br/>
                </div>
                <h2 className='plan_subtitle'>{t('Plan.planThirdSubtitle')}</h2><br/>
                <div>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentyeSixthParagraphTitle')}</h3><br/>
                        {t('Plan.twentyeSixthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentyeSeventhParagraphTitle')}</h3><br/>
                        {t('Plan.twentyeSeventhParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentyeEightParagraphTitle')}</h3><br/>
                        {t('Plan.twentyeEightParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.twentyeNinthParagraphTitle')}</h3><br/>
                        {t('Plan.twentyeNinthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtyFirstParagraphTitle')}</h3><br/>
                        {t('Plan.thirtyFirstParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtySecondParagraphTitle')}</h3><br/>
                        {t('Plan.thirtySecondParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtyThirdthParagraphTitle')}</h3><br/>
                        {t('Plan.thirtyThirdthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtyFourthParagraphTitle')}</h3><br/>
                        {t('Plan.thirtyFourthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtyFifthParagraphTitle')}</h3><br/>
                        {t('Plan.thirtyFifthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtySixthParagraphTitle')}</h3><br/>
                        {t('Plan.thirtySixthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtySeventhParagraphTitle')}</h3><br/>
                        {t('Plan.thirtySeventhParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtyEightParagraphTitle')}</h3><br/>
                        {t('Plan.thirtyEightParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.thirtyNinthParagraphTitle')}</h3><br/>
                        {t('Plan.thirtyNinthParagraphText')}
                    </p><br/>
                    <p><h3 style={{fontSize: '20px'}}>{t('Plan.fourtyFirstParagraphTitle')}</h3><br/>
                        {t('Plan.fourtyFirstParagraphText')}
                    </p>
                </div>
            </div>
        </section>
    );
}

export default Plan;