import React from 'react';
import { useTranslation } from 'react-i18next';
import AuthorizedMenu from './AuthorizedMenu/AuthorizedMenu';
import BurgerMenu from './BurgerMenu/BurgerMenu';
import LangSelect from './LangSelect/LangSelect';
import keycloak from '../../../Keycloak';
import { downloadAppsLinks } from '../../../constants/downloadApps';
import { useAuth } from '../../../contexts/authContext';
import tonnaLogoIcon from '../../../assets/newLanding/TonnaLogo.svg'
import buttonArrow from '../../../assets/newLanding/header/buttonArrow.svg';
import appScreensImage from '../../../assets/newLanding/hero/appScreens.png';
import downloadAppleIcon from '../../../assets/newLanding/hero/downloadApple.svg';
import downloadGooglePlayIcon from '../../../assets/newLanding/hero/downloadGooglePlay.svg';
import downloadRustoreIcon from '../../../assets/newLanding/hero/downloadRustore.svg';
import downloadAppGalleryIcon from '../../../assets/newLanding/hero/downloadAppGallery.svg';
import downloadAppGalleryQrImage from '../../../assets/newLanding/hero/downloadAppGalleryQr.png';
import downloadRustoreQrImage from '../../../assets/newLanding/hero/downloadRustoreQr.png';
import downloadAppleQrImage from '../../../assets/newLanding/hero/downloadAppleQr.png';
import downloadGooglePlayQrImage from '../../../assets/newLanding/hero/downloadGooglePlayQr.png';
import styles from './styles.module.css';
import BeneficiaryCard from "../../components/Subscriptions/BeneficiaryCard/BeneficiaryCard";
import { login } from '../../../utils/auth';

const Button = (props) => {
    const { children, after, href, onClick } = props;

    return (
        <a className={styles.button} href={href} onClick={onClick}>
            <div className={styles.buttonText}>{children}</div>
            {after}
        </a>
    );
};

const DownloadItem = (props) => {
    const { children, href, iconSrc, qrSrc } = props;

    return (
        <div className={styles.downloadItem}>
            <a className={styles.downloadItemButton} href={href}>
                <img src={iconSrc} alt="" />
                <div className={styles.downloadItemButtonText}>{children}</div>
            </a>
            <img src={qrSrc} className={styles.downloadItemQr} alt="" />
        </div>
    );
};

const Hero = () => {
    const { isAuth } = useAuth();
    const { t } = useTranslation();

    return (
        <div className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <header className={styles.header} style={isAuth ? {paddingBottom:"25px"} :null}>
                    <div className={styles.leftSection}>
                        <img className={styles.logo} src={tonnaLogoIcon} alt="" />
                        <LangSelect />
                    </div>
                    <div className={styles.rightSection}>
                        {isAuth && <>
                            <div className={styles.authMenuHorizontalMenu}><AuthorizedMenu /></div>
                            <div className={styles.authMenuBurgerMenu}><BurgerMenu /></div>
                        </>}
                        {!isAuth && (
                            <>
                                <Button onClick={() => login()}>{t('Login.logIn')}</Button>
                                <Button onClick={() => keycloak.register()} after={<img src={buttonArrow} alt="" />}>{t('Login.singUp')}</Button>
                                <BurgerMenu />
                            </>
                        )}
                    </div>
                    <div className={styles.mobileRightSection}>
                        {!isAuth && (
                            <Button onClick={() => login()}>{t('Login.logIn')}</Button>
                        )}
                    </div>
                </header>
                {isAuth && <BeneficiaryCard/>}
                <div className={styles.aboutWrapper}>
                    <h1 className={styles.title}><span className={styles.titleCompanyName}>{t('Lending.mainTitle')}</span>{t('Lending.paas')}</h1>
                    <img className={styles.image} src={appScreensImage} alt="" />
                    <div className={styles.downloadItemsList}>
                        <DownloadItem
                            iconSrc={downloadAppleIcon}
                            qrSrc={downloadAppleQrImage}
                            href={downloadAppsLinks.appStore}
                        >
                            {t('Lending.downloadInAppStore')}
                        </DownloadItem>
                        <DownloadItem
                            iconSrc={downloadGooglePlayIcon}
                            qrSrc={downloadGooglePlayQrImage}
                            href={downloadAppsLinks.googlePlay}
                        >
                            {t('Lending.downloadInGooglePlay')}
                        </DownloadItem>
                        <DownloadItem
                            iconSrc={downloadRustoreIcon}
                            qrSrc={downloadRustoreQrImage}
                            href={downloadAppsLinks.ruStore}
                        >
                            {t('Lending.downloadInRuStore')}
                        </DownloadItem>
                        <DownloadItem
                            iconSrc={downloadAppGalleryIcon}
                            qrSrc={downloadAppGalleryQrImage}
                            href={downloadAppsLinks.appGallery}
                        >
                            {t('Lending.downloadInAppGallery')}
                        </DownloadItem>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Hero;
