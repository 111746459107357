import React from 'react'
import ArrowSVG from '../../../assets/icon/arrowLong.svg'
import 'moment/locale/ru'
import moment from 'moment'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { enableOffer } from '../Offer/offerSlice'
import { fetchMyOffers } from '../offersSlice'
import { fetchBeneficiary } from '../../Verification/verificationSlice'
import Modal from 'antd/lib/modal/Modal'
import { priceMask } from '../../components/masks'
import Ruble  from '../../../assets/icon/₽.svg'
import {Tooltip} from "antd";
function Offer(props) {
    const [isModalVisible, setIsModalVisible] = useState(false)
    const history = useHistory()
    const isTrade = window.location.pathname.split('/')[1] === 'offers' ? false : true
    const offerInfo = !isTrade ? props.data : props.data.offer_info
    const price = priceMask(offerInfo.price)
    const id = !isTrade ? offerInfo.id : props.data.id
    const beneficiary = useSelector(state => state.verificationSlice.beneficiary)
    const date = offerInfo.date
    const unload = offerInfo.unload
    const upload = offerInfo.upload
    const phoneNumber = offerInfo.contact
    const type = offerInfo.offer_type
    const status = offerInfo.status
    const { t } = useTranslation()
    const tradeState = !isTrade ? offerInfo.trade_state : props.data.state
    const isMine = !isTrade ? offerInfo.is_mine : props.data.is_customer
    const role = isTrade ? isMine ? t('respond.buyer') : t('respond.supplierr') : null
    const carrierContact = props.data.carrier_contact
    const [statusInfo, setStatusInfo] = useState({})
    const [btnInfo, setBtnInfo] = useState({})
    const dispatch = useDispatch()
    console.log(type)
    const [modalInfo, setModalInfo] = useState({
        title: '',
        description: ''
    })
    const [isEnoughMoney, setIsEnoughMoney] = useState(true)

    const getTitle = () => {
        if (type === 'cargo') {
            return t('NewApplication.cargoTransportation')
        }
        if (type === 'delivery') {
            return t('NewApplication.supplyOfGoods')
        }
        return t('NewApplication.transportationOfPassengers')
    }
    const handleOk = () => {
        setIsModalVisible(false)
        if (!beneficiary) {
            history.push(`/verification/${'tochka'}`)
        }
        else {
            history.push(`/wallet`)

            history.push({
                pathname: '/wallet',
                state: { price: price }
            });
        }
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const showModal = () => {
        setIsModalVisible(true)
    }

    const formatDate = moment(date).format('DD MMM YYYY')
    const handleClick = () => {
        if (status !== 'disabled' || tradeState) {
            history.push(`/${isTrade ? 'trade' : 'offer'}/${id}`)
        }
    }
    const activateOffer = () => {
        if (status === 'disabled' && isMine && !tradeState) {
            dispatch(fetchBeneficiary({ verifyType: 'tochka' })).then((params) => {
                if (!params.error) {
                    dispatch(enableOffer({ id })).then((params) => {
                        if (params.error) {
                            setModalInfo({
                                title: 'carryOutWarning',
                                description: ''
                            })
                            setIsEnoughMoney(false)
                            showModal()
                        }
                        else {
                            dispatch(fetchMyOffers())
                        }

                    })
                }
                else if (params.error) {
                    setModalInfo({
                        title: t('NewApplication.newApplicationWarning'),
                        description: ''
                    })
                    showModal()
                }
            })
        }
    }
    useEffect(() => {
        if (status === 'disabled' && isMine) {
            setStatusInfo({
                class: "status-cancel",
                text: t('respond.notActive')
            })

            setBtnInfo({
                class: "btn red",
                text: t('respond.activate')
            })
        }
        else if (status === 'enabled') {
            setStatusInfo({
                class: "status-paid",
                text: t('Applications.waitingFor')
            })
            if (!isMine) {
                setBtnInfo({
                    class: "btn",
                    text: t('Applications.reply')
                })
            }

        }
        if (tradeState === 'in_progress') {
            setStatusInfo({
                class: "status-in-processing",
                text: t('NewApplication.performed')
            })
            setBtnInfo({
                class: "btn",
                text: t('NewApplication.details')
            })
        }
        if (tradeState === 'finished') {
            setStatusInfo({
                class: "status-success",
                text: t('respond.fulfilled')
            })
            setBtnInfo({
                class: "btn",
                text: t('NewApplication.details')
            })

        }
        if (isMine && (status === 'enabled' || tradeState)) {
            if (isTrade) {
                setBtnInfo({
                    class: "btn",
                    text: t('NewApplication.myOfferTrade')
                })
            }
            else {
                setBtnInfo({
                    class: "btn",
                    text: t('NewApplication.myOfferDetails')
                })
            }
        }

    }, [isMine, isTrade, status, t, tradeState])
    return (
        <div onClick={handleClick} style={{ cursor: 'pointer' }} className="card">
            <span className="card_header">
                <div style={{ display: 'flex', alignItems: 'center' }} className="offer-number">№{id}
                    <div style={{ marginLeft: '10px' }} className={`${statusInfo.class}`}>{statusInfo.text}</div>
                    {statusInfo.class === 'status-in-processin' || statusInfo.class === 'status-paid'
                        ? <Tooltip title={t('respond.rubleTooltip')}><img style={{marginLeft: '10px'}} src={Ruble} alt=""/></Tooltip>
                        : ''
                    }
                </div>
                <span style={{ display: 'flex', alignItems: 'center', }} className="date">{formatDate}
                </span>
            </span>
            <h2 className="card_title">{getTitle()}</h2>
            <div className="card_footer">
                <span className="card_info">
                    <span className="card_info_item"><span>{t('Applications.route')}: </span>{upload.address.city ? upload.address.city : upload.address.state}<img
                        style={{ margin: '0 2px', height: 19, width: 33 }} src={ArrowSVG} alt="" />{unload.address.city ? unload.address.city : unload.address.state}</span>
                    <span className="card_info_item"><span>{t('NewApplication.customerContacts')}: </span> {phoneNumber}</span>
                    {carrierContact ? <span className="card_info_item"><span>{t('NewApplication.carrierContacts')}: </span> {carrierContact}</span> : null}
                    {role ? <span className="card_info_item"><span>{t('NewApplication.myRole')}: </span> {role}</span> : null}

                    <span className="card_info_item"><span>{t('NewApplication.price')}: </span> {price} ₽</span>
                </span>
                <button onClick={activateOffer} style={{ marginTop: 20 }} className={btnInfo.class}>{btnInfo.text}</button>
            </div>
            <Modal closeIcon={<div className='close'>{t('NewApplication.close')}</div>} title={modalInfo.title} okText={t('Wallet.verify')} cancelText={t('NewApplication.rejection')}
                   visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            </Modal>
        </div>
    )
}

export default Offer
