import React from 'react';
import useScrollRestoration from '../../hooks/useScrollRestoration';

const ScrollRestoration = ({ children }) => {
    useScrollRestoration();

    return <>{children}</>;
};

export default ScrollRestoration;
