import React from 'react';
import { prepareClassName } from '../../../../utils/styles';
import notificationsIcon from '../../../../assets/newLanding/header/notifications.svg';
import tariffsIcon from '../../../../assets/newLanding/header/tariffs.svg';
import chatIcon from '../../../../assets/newLanding/header/chat.svg';
import newRequestIcon from '../../../../assets/newLanding/header/newRequest.svg';
import requestsIcon from '../../../../assets/newLanding/header/requests.svg';
import mapIcon from '../../../../assets/newLanding/header/map.svg';
import walletIcon from '../../../../assets/newLanding/header/wallet.svg';
import logoutIcon from '../../../../assets/newLanding/header/logout.svg';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { logoutUser } from '../../../../utils/auth';
import { Link } from 'react-router-dom';

const MenuItem = (props) => {
    const { label, iconSrc, href, className: classNameProp, ariaLabel, onClick } = props;

    const className = prepareClassName([styles.menuItem, classNameProp]);

    const Component = !href ? 'a' : Link;

    return (
        <Component className={className} to={href} onClick={onClick} aria-label={ariaLabel}>
            <img className={styles.menuItemIcon} src={iconSrc} alt="" />
            {label && <div className={styles.menuItemText}>{label}</div>}
        </Component>
    );
};

const AuthorizedMenu = () => {
    const { t } = useTranslation();

    return (
        <nav className={styles.wrapper}>
            <MenuItem href="/notifications" iconSrc={notificationsIcon} />
            <MenuItem className={styles.menuItemTariff} label={t('Menu.tariffs')} href="/subscriptions" iconSrc={tariffsIcon} />
            <MenuItem label={t('Menu.chats')} href="/chats" iconSrc={chatIcon} />
            <MenuItem label={t('Menu.newRequest')} href="/newOffer" iconSrc={newRequestIcon} />
            <MenuItem label={t('Menu.requests')} href="/offers" iconSrc={requestsIcon} />
            <MenuItem label={t('Menu.map')} href="/map" iconSrc={mapIcon} />
            <MenuItem label={t('Menu.wallet')} href="/wallet" iconSrc={walletIcon} />
            <MenuItem iconSrc={logoutIcon} onClick={logoutUser} aria-label={t('Lending.exit')} />
        </nav>
    );
};

export default AuthorizedMenu;
