import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";


const BeneficiaryCard=()=>{
    const {t} = useTranslation()

    const beneficiary = useSelector(state=> state.verificationSlice.beneficiary)
    const [customerName, setCustomerName] = useState(localStorage.getItem('beneficiary'))
    const [inn, setInn] = useState(localStorage.getItem('beneficiary'))
    const [subscriptionType, setSubscriptionType] = useState('Start')

    useEffect(() => {
        try {
            if (beneficiary) {
                let customerData = beneficiary.beneficiary_data
                let inn=beneficiary.inn
                setCustomerName(customerData.name ? customerData.name : `${customerData.last_name} ${customerData.first_name} ${customerData.middle_name}`)
                setInn(inn)
            }
        } catch (error) {
            setCustomerName('')
            setInn('')
        }
    }, [beneficiary])


  return (<>
      {beneficiary.beneficiary_data ? <div className='subscription_card_info'>
         <div className='subscription_card_info_title_block'>
            <div className='subscription_card_info_title'>{customerName}</div>
              <div className='subscription_card_info_title inn'>{t('Subscriptions.inn')} {inn}</div>
        </div>
        <div className='subscription_card_info_date_block '>
            <div className='subscription_card_info_date'>
                <b>{subscriptionType}</b>
                <span className='info_date'>{'до 23.12.26'}</span>
            </div>
            <button className="improve_btn">{beneficiary.beneficiary_data ? t('Subscriptions.btnImprove') : t('Subscriptions.btnStart')}</button>
        </div>
    </div> :
       <div className='subscription_card_info'>
          <div className='subscription_card_info_title_block'>
                  <div className='subscription_card_info_verification'>{t('Subscriptions.verification')}</div>
                  <div className='subscription_card_info_title inn'>{t('Subscriptions.usePlatform')}</div>
          </div>
          <div className='subscription_card_info_date_block ' >
              <div className='subscription_card_info_date' >
                  <b>{"Start"}</b>
                  <span className='info_date'>{t('Subscriptions.freeDays')}</span>
              </div>
              <button className="improve_btn">{ t('Subscriptions.btnStart')}</button>
          </div>
      </div>}
  </>)
}


export default BeneficiaryCard