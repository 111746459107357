import React, {useEffect} from 'react';
import GoBack from "../components/GoBack/GoBack";
import {useTranslation} from "react-i18next";
import  EllipseSVG from '../../assets/icon/notification_ellipse.svg'
import  EllipseGraySVG from '../../assets/icon/ellipse-gray.svg'
import {useDispatch, useSelector} from "react-redux";
import {fetchNotifications, markAsRead} from "./notificationsSlice";
import './notification.css'
import Loader from "../components/Loader";
import {Helmet} from "react-helmet";

const Notifications = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const loading = useSelector(state => state.notificationsSlice.loading)
    const notifications = useSelector(state => state.notificationsSlice.notifications)

    const handleMarkAll = () => {
        dispatch(markAsRead())
            .then(() => dispatch(fetchNotifications()))
    }
    useEffect(() => {
        dispatch(fetchNotifications())
    },[dispatch])

    return (
        <section className='main'>
            <div className='content-wrapper'>
                <GoBack prevPageName={t('Applications.applicationManagement')}/>
                <Helmet>
                    <title>{t('notifications')}</title>
                </Helmet>
                <h1 className='title'>{t('notifications')}</h1>
                {loading
                    ? <Loader />
                    : <div>
                        {notifications?.map(item =>
                        <div key={item.id} className='notification_item'>
                            <div  className='ellipse'>
                                <img  src={item.was_read? EllipseGraySVG : EllipseSVG} alt=""/>
                            </div>
                            <div className='notification_item_body'>
                                <div className='notification_title'>
                                    <div className='card_title'>{item.text.split(': ')[0]}</div>
                                    <div className='date'>{item.date}</div>
                                </div>
                                <div className='notification_text'>
                                    {item.text.split(': ')[1] !== 'null' ? item.text.split(': ')[1] : null}
                                </div>
                            </div>
                        </div>
                        )}
                        {notifications
                            ? <div className='notification_button_container'>
                                <button style={{maxWidth: '400px'}} className='btn' onClick={handleMarkAll}>{t('markAllRead')}</button>
                              </div>
                            : <span className='empty_notifications'>{t('noNotificationsYet')}</span>
                        }
                    </div>
                }
            </div>
        </section>
    );
};

export default Notifications;