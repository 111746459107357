import React, {useState} from 'react';
import '../body.css'
import {ReactComponent as ArrowSVG} from "../../../../assets/newLanding/Vector.svg";
import {ReactComponent as ArrowActiveSVG} from "../../../../assets/newLanding/VectorActive.svg";
import {useTranslation} from "react-i18next";
import SecondaryTitle from '../../SecondaryTitle/SecondaryTitle';
import styles from './styles.module.css';

const Questions = () => {

    const {t} = useTranslation()
    const [applicationIsOpen, setApplicationIsOpen] = useState(false);
    const [orderIsOpen, setOrderIsOpen] = useState(false);
    const [verificationIsOpen, setVerificationIsOpen] = useState(false);
    const [warrantyIsOpen, setWarrantyIsOpen] = useState(false);

    const handleApplicationClick = () => {
        setApplicationIsOpen(!applicationIsOpen);
        setOrderIsOpen(false)
        setVerificationIsOpen(false)
        setWarrantyIsOpen(false)
    };

    const handleOrderClick = () => {
        setOrderIsOpen(!orderIsOpen)
        setApplicationIsOpen(false);
        setVerificationIsOpen(false)
        setWarrantyIsOpen(false)
    };

    const handleVerificationClick = () => {
        setVerificationIsOpen(!verificationIsOpen)
        setApplicationIsOpen(false);
        setOrderIsOpen(false)
        setWarrantyIsOpen(false)
    };

    const handleWarrantyClick = () => {
        setWarrantyIsOpen(!warrantyIsOpen)
        setOrderIsOpen(false)
        setVerificationIsOpen(false)
        setApplicationIsOpen(false);
    };

    return (
        <div className={styles.wrapper}>
            <SecondaryTitle>{t("Lending.popularQuestions")}</SecondaryTitle>
            <div className='question_container'>
                <div className='question'
                     style={{
                         color: applicationIsOpen ? '#fff' : '#131413',
                         backgroundColor: applicationIsOpen ? '#6A62F4' : 'transparent',
                     }}
                     onClick={handleApplicationClick}
                >
                    <div className='question_title'>{t("Lending.whatDataShould")}</div>
                    <div className={styles.arrow} style={{
                        transition: 'transform 0.2s',
                        transform: applicationIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}>
                        {applicationIsOpen ? <ArrowActiveSVG/> : <ArrowSVG/>}
                    </div>
                </div>
                {applicationIsOpen && (
                    <div className='question_text'>
                        <ul className={"application_list"}>
                            <li>
                                {t("Lending.whatDataShouldOne")}
                            </li>
                            <li>
                                {t("Lending.whatDataShouldTwo")}
                            </li>
                            <li>
                                {t("Lending.whatDataShouldThree")}
                            </li>
                            <li>
                                {t("Lending.whatDataShouldFour")}
                            </li>
                            <li>
                                {t("Lending.whatDataShouldFive")}
                            </li>
                        </ul>
                    </div>
                )}
            </div>
            <div className='question_container'>
                <div className='question'
                     style={{
                         color: orderIsOpen ? '#fff' : '#131413',
                         backgroundColor: orderIsOpen ? '#6A62F4' : 'transparent',
                     }}
                     onClick={handleOrderClick}
                >
                    <div className='question_title'>{t("Lending.howDoGet")}</div>
                    <div className={styles.arrow} style={{
                        transition: 'transform 0.2s',
                        transform: orderIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}>
                        {orderIsOpen ? <ArrowActiveSVG/> : <ArrowSVG/>}
                    </div>
                </div>
                {orderIsOpen && (
                    <div className='question_text'>
                        {t("Lending.howDoGetAnswer")}
                    </div>
                )}
            </div>
            <div className='question_container'>
                <div className='question'
                     style={{
                         color: verificationIsOpen ? '#fff' : '#131413',
                         backgroundColor: verificationIsOpen ? '#6A62F4' : 'transparent',
                     }}
                     onClick={handleVerificationClick}
                >
                    <div className='question_title'>{t("Lending.verificationPlatform")}</div>
                    <div className={styles.arrow} style={{
                        transition: 'transform 0.2s',
                        transform: verificationIsOpen? 'rotate(180deg)' : 'rotate(0deg)'
                    }}>
                        {verificationIsOpen ? <ArrowActiveSVG/> : <ArrowSVG/>}
                    </div>
                </div>
                {verificationIsOpen && (
                    <div className='question_text'>
                        <p>{t("Lending.fillForm")}</p>
                        {t("Lending.formAnswer")}
                    </div>
                )}
            </div>
            <div className='question_container'>
                <div className='question'
                     style={{
                         color: warrantyIsOpen ? '#fff' : '#131413',
                         backgroundColor: warrantyIsOpen ? '#6A62F4' : 'transparent',
                     }}
                     onClick={handleWarrantyClick}
                >
                    <div className='question_title'>
                        {t("Lending.whyTINSufficient")}
                    </div>
                    <div className={styles.arrow} style={{
                        transition: 'transform 0.2s',
                        transform: warrantyIsOpen ? 'rotate(180deg)' : 'rotate(0deg)'
                    }}>
                        {warrantyIsOpen ? <ArrowActiveSVG/> : <ArrowSVG/>}
                    </div>
                </div>
                {warrantyIsOpen && (
                    <div className='question_text'>
                        {t("Lending.whyTINSufficientAnswer")}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Questions;