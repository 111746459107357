import React from 'react';

import {useTranslation} from "react-i18next";
import ProPayment from "./ProPayment";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'

import {increaseInvoiceNumber} from '../subscriptionsSlice';
import {GetInvoiceNumber} from '../../GetInvoiceNumber';
import ActiveCheck from "../../../../assets/newLanding/rates/activeCheck.svg";

const Pro = ({active}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const invoice = useSelector(state => state.walletSlice.wallet.invoice)
    const invoiceNumber = useSelector(state => state.subscriptionsSlice.invoiceNumber)

    // useEffect(() => {
    //     dispatch(fetchInvoice({}))
    // }, [dispatch])

    const handlePrint = useReactToPrint({
        content: () => document.querySelector('.print_pro'),
        documentTitle: `Счет на оплату подписки TONNA Pro ${moment().format('DD.MM.yyyy')}`
    })

    return (
        <div className="subscription_card" >
            <ProPayment token={invoice?.invoice_uuid} invoiceNumber={GetInvoiceNumber(invoiceNumber)}/>
            <div className='subscription_title_container'>
                <span className="subscription_card_title blue_title">Pro</span>
                {active && <div className='subscription_active_status'>{t('Subscriptions.active')}
                    <img src={ActiveCheck} alt={''}/>
                </div>}
            </div>
            <div className='subscription_card_content'>
                <div className='subscription_card_about'>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>3% {t('Subscriptions.Nitro.first')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>30 {t('Subscriptions.Nitro.second')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.third')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.third')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.fifth')}
                                {t('Subscriptions.Pro.fifthStrong')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.fourth')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.sixth')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.second')}</span>
                        </div>
                        <div className='about_items_container' style={{marginBottom:'28px'}}>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Pro.first')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='subscription_subtitle_container' style={{marginTop:'0'}} >
                <div className='subscription_card_price'><b style={{margin:'0 40px 0 0',fontWeight:'700',fontSize: '20px'}}><strike
                    style={{marginRight:"4px"}}> 228 000
                </strike>₽</b>
                    <b style={{fontSize: '20px',fontWeight:'700'}}>187 200 ₽/{t('Subscriptions.Nitro.year')}</b>
                </div>
                <button onClick={() => {
                    handlePrint();
                    dispatch(increaseInvoiceNumber())
                }} style={{marginTop: '16px'}}
                        className="chose_btn">{t('Subscriptions.Pro.btn')}
                </button>
                <button className='download_btn'>
                    {'Скачать счёт'}
                </button>
            </div>
        </div>
    );
};

export default Pro;