import React from 'react'
import GoBack from './components/GoBack/GoBack'
import { useTranslation } from 'react-i18next'
import {Helmet} from "react-helmet";

function PersonalData (props) {
  const { t } = useTranslation()
  return (
    <section>
      <div className="content-wrapper">
        <GoBack prevPageName={t('Verification.goBack')}/>
        <Helmet>
          <title>{t('PersonalData.personalData')}</title>
        </Helmet>
        <h1 style={{ marginBottom: 20 }} className="title">{t('PersonalData.personalData')}</h1>
        <div>
          <div>
            <div className="offerta_body">
              <div className="offerta_number">№</div>
              <div className="offerta_title">{t('UserAgreement.name')}</div>
              <div className="offerta_text">{t('UserAgreement.content')}</div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number"></div>
              <div className="offerta_title"></div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.description')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">1</div>
              <div className="offerta_title">{t('UserAgreement.generalRegulations.title')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.General.first')}
                </p>
                <p>
                  {t('PersonalData.General.second')}
                </p>
                <p>
                  {t('PersonalData.General.third')}
                </p>
                <p>
                  {t('PersonalData.General.fourth')}
                </p>
                <p>
                  {t('PersonalData.General.fifth')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">2</div>
              <div className="offerta_title">{t('PersonalData.Purposes.purposes')}
              </div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Purposes.first')}
                </p>
                <ul>
                  <li>{t('PersonalData.Purposes.firstEl')}</li>
                  <li>{t('PersonalData.Purposes.secondEl')}</li>
                  <li>{t('PersonalData.Purposes.thirdEl')}</li>
                  <li>{t('PersonalData.Purposes.fourthEl')}</li>
                  <li>{t('PersonalData.Purposes.fifthEl')}</li>
                  <li>{t('PersonalData.Purposes.sixthEl')}</li>
                  <li>{t('PersonalData.Purposes.seventhEl')}</li>
                </ul>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">3</div>
              <div className="offerta_title">
                {t('PersonalData.Process.process')}
              </div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Process.first')}
                </p>
                <p>
                  {t('PersonalData.Process.second')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">4</div>
              <div className="offerta_title">{t('PersonalData.Rules.rules')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Rules.first')}
                </p>
                <p>
                  {t('PersonalData.Rules.second')}
                </p>
                <p>
                  {t('PersonalData.Rules.third')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">5</div>
              <div className="offerta_title">{t('PersonalData.UserObligations.userObligations')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.UserObligations.first')}
                </p>
                <p>
                  {t('PersonalData.UserObligations.second')}
                </p>
                <p>
                  {t('PersonalData.UserObligations.third')}
                </p>
                <p>
                  {t('PersonalData.UserObligations.fourth')}
                </p>
                <p>
                  {t('PersonalData.UserObligations.fifth')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">6</div>
              <div className="offerta_title">{t('PersonalData.Block.block')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Block.first')}
                </p>
                <ul>
                  <li>{t('PersonalData.Block.firstEl')}</li>
                  <li>{t('PersonalData.Block.secondEl')}</li>
                </ul>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">7</div>
              <div className="offerta_title">{t('PersonalData.Destroy.destroy')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Destroy.first')}
                </p>
                <ul>
                  <li>{t('PersonalData.Destroy.firstEl')}</li>
                  <li>{t('PersonalData.Destroy.secondEl')}</li>
                  <li>{t('PersonalData.Destroy.thirdEl')}</li>
                  <li>{t('PersonalData.Destroy.fourthEl')}</li>
                  <li>{t('PersonalData.Destroy.fifthEl')}</li>
                </ul>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">8</div>
              <div className="offerta_title">{t('PersonalData.Protection.protection')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Protection.first')}
                </p>
                <p>
                  {t('PersonalData.Protection.second')}
                </p>
                <p>
                  {t('PersonalData.Protection.third')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">9</div>
              <div className="offerta_title">{t('PersonalData.Responsibility.responsibility')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Responsibility.first')}
                </p>
                <p>
                  {t('PersonalData.Responsibility.second')}
                </p>
              </div>
            </div>
            <div className="offerta_body">
              <div className="offerta_number">10</div>
              <div className="offerta_title">{t('PersonalData.Other.other')}</div>
              <div className="offerta_text">
                <p>
                  {t('PersonalData.Other.first')}
                </p>
                <p>
                  {t('PersonalData.Other.second')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}

export default PersonalData
