import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

const GoTermsOfUse = ({ btnName }) => {
    const { t } = useTranslation()
    return (
        <div style={{ color: '#615FFB', margin: '13px 0 21px', lineHeight: '20px' }}>
            {`${t('respond.respondWarningFirst')} ${btnName} ${t('respond.respondWarningSecond')}`} <span style={{ textDecoration: 'underline', cursor: 'pointer' }}><NavLink
                to="/offerta" style={{ color: '#615FFB' }}> {t('respond.termsLink')}
            </NavLink>
            </span>
        </div>
    )
}
export default GoTermsOfUse
