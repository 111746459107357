import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchMyTrades } from '../offersSlice'
import Loader from '../../components/Loader'
import Offer from '../components/Offer'
import {useTranslation} from "react-i18next";

function MyTrades() {
    const dispatch = useDispatch()
    const { t } = useTranslation()
    const requests = useSelector(state => state.offersSlice.requests)
    const isLoaded = useSelector(state => state.offersSlice.tradesLoaded)
    const requestItems = isLoaded ? requests.length > 0 ? requests.map((request) => {
        return (
            <Offer key={request.id} data={request} />
        )
    }) : <div style={{ color: '#CBCBCB', textAlign: 'center' }}>{t('Applications.no_trades')}</div> : null

    useEffect(() => {
            dispatch(fetchMyTrades())
    }, [dispatch])

    return (
        <section className="PublicOffers">
            <div>
                {isLoaded ? requestItems : <Loader />}
            </div>
        </section>
    )
}

export default MyTrades
