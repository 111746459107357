import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import {api, profileApi} from "../../../Store/api";
import {fetchToken} from "../../Chats/ChatSlice";

const initialState = () => {
  return {
    invoiceNumber: 10,
    linkProceed:''
  }
}

export const fetchProceed = createAsyncThunk(
    'subscriptions/fetchProceed',
    async () => {
        try {
            const response = await profileApi.get('/payment/proceed')
            console.log(response.headers)
        } catch (err) {
            console.log(err.response.headers.location)
        }

        // return document.location='http://profile.tonna.io:8088/login'
    }
)

const subscriptionsSlice = createSlice({
  name: 'subscriptionsSlice',
  initialState: initialState(),
  reducers: {
    increaseInvoiceNumber(state) {
      state.invoiceNumber += 1;
    },
  },
  // extraReducers:{
  //   [fetchProceed.pending]: (state) => {
  //     // state.loading = true
  //     state.error.failed = false
  //   },
  //   [fetchProceed.fulfilled]: (state, action) => {
  //     // state.loading = false
  //     console.log(action.payload)
  //   },
  //   [fetchProceed.rejected]: (state, action) => {
  //     // state.loading = false
  //     state.error.failed = true
  //     state.error.message = action.payload
  //   }
  // }
});

export const { increaseInvoiceNumber } = subscriptionsSlice.actions

export default persistReducer(
  { key: 'subscriptionsSlice', storage, whitelist: ['invoiceNumber'] },
  subscriptionsSlice.reducer
);
