import React, { useEffect } from 'react'
import Offer from '../components/Offer'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPublicOffers } from '../offersSlice'
import Loader from '../../components/Loader'
import {useTranslation} from "react-i18next";

function PublicOffers() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const requests = useSelector(state => state.offersSlice.requests)
  const isLoaded = useSelector(state => state.offersSlice.offersLoaded)
  const someVal = useSelector(state => state.offersSlice.searchValue)
  const requestItems = isLoaded && requests ? requests.length > 0 ? requests.filter(item => {
    return someVal.toLowerCase() === ''
    ? item
    : item.unload.address.city?.toLowerCase().includes(someVal.toLowerCase())
  }).map((request) => {
    return (
      <Offer key={request.id} data={request} />
    )
  }) : <div style={{ color: '#CBCBCB', textAlign: 'center' }}>{t('Applications.no_offers')}</div> : null

  useEffect(() => {
    dispatch(fetchPublicOffers())
  }, [dispatch, someVal])
  return (
    <section className="PublicOffers">
      <div>
        {isLoaded ? requestItems : <Loader />}
      </div>
    </section>
  )
}

export default PublicOffers
