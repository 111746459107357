import React from 'react';
import {useTranslation} from "react-i18next";
import NitroPayment from "./NitroPayment";
import {useReactToPrint} from "react-to-print";
import moment from "moment";
import {useSelector, useDispatch} from 'react-redux'
import {fetchProceed, increaseInvoiceNumber} from '../subscriptionsSlice';
import {GetInvoiceNumber} from '../../GetInvoiceNumber';
import ActiveCheck from "../../../../assets/newLanding/rates/activeCheck.svg";


const Nitro = ({active}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const invoice = useSelector(state => state.walletSlice.wallet.invoice)
    const invoiceNumber = useSelector(state => state.subscriptionsSlice.invoiceNumber)

    // useEffect(() => {
    //     dispatch(fetchInvoice({}))
    // }, [dispatch])

    const handlePrint = useReactToPrint({
        content: () => document.querySelector('.print_nitro'),
        documentTitle: `Счет на оплату подписки TONNA Nitro ${moment().format('DD.MM.yyyy')}`
    })
    const handleProceed=()=>{
        dispatch(fetchProceed())
    }


    return (
        <div className="subscription_card">
            <NitroPayment token={invoice?.invoice_uuid} invoiceNumber={GetInvoiceNumber(invoiceNumber)}/>
            <div className='subscription_title_container'>
                <span className="subscription_card_title green_title">Nitro</span>
                {active && <div className='subscription_active_status'>{t('Subscriptions.active')}
                    <img src={ActiveCheck} alt={''}/>
                </div>}
            </div>
            <div className='subscription_card_content'>
                <div className='subscription_card_about'>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>4% {t('Subscriptions.Nitro.first')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>10 {t('Subscriptions.Nitro.second')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.third')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.fourth')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.fifth')}
                                {t('Subscriptions.Nitro.fifthStrong')}</span>
                        </div>
                        <div className='about_items_container' style={{marginBottom:'34px'}}>
                            {/*<img src={markImg} alt=""/>*/}
                            <span >{t('Subscriptions.Nitro.sixth')}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='subscription_subtitle_container'>
                <div className='subscription_card_price'><b style={{marginRight:'39px',fontWeight:'700',fontSize: '20px'}}><strike
                    style={{marginRight:"4px"}}> 200 000
                </strike>₽</b>
                    <b style={{fontSize: '20px',fontWeight:'700'}}>131 000 ₽/{t('Subscriptions.Nitro.year')}</b>
                </div>
                <button onClick={() => {
                    handlePrint();
                    dispatch(increaseInvoiceNumber())
                }} style={{marginTop: '16px'}}
                        className="chose_btn">{t('Subscriptions.Nitro.btn')}
                </button>
                <button className='download_btn' onClick={()=>{
                    handleProceed()
                }}>
                    {'Скачать счёт'}
                </button>
            </div>
        </div>
    );
};

export default Nitro;