import React from 'react';
import {useTranslation} from "react-i18next";
import GoBack from "./components/GoBack/GoBack";
import {Helmet} from "react-helmet";

function FrameworkSupplyContract(props) {
    const { t } = useTranslation()
    return (
        <section>
            <div className="content-wrapper">
                <GoBack prevPageName={t('Verification.goBack')}/>
                <Helmet>
                    <title>{t('FrameworkSupplyContract.frameworkSupplyContract')}</title>
                </Helmet>
                <h1 style={{ marginBottom: 20, textAlign: 'center' }}
                    className="title">{t('FrameworkSupplyContract.frameworkSupplyContract')}</h1>
                <div>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 75px 30px 0' }}>
                            <span>{t('FrameworkContract.moscow')}</span>
                            <span>{t('FrameworkContract.date')}</span>
                        </div>
                        <div style={{ margin: '0 0 10px 0' }}><span><b>{t('FrameworkSupplyContract.buyer')}</b></span></div>
                        <div style={{ margin: '0 0 10px 0' }}><span><b>{t('invoice.inn')}</b></span></div>
                        <div style={{ margin: '0 0 10px 0' }}><span><b>{t('FrameworkSupplyContract.supplier')}</b></span></div>
                        <div style={{ margin: '0 0 10px 0' }}><span><b>{t('invoice.inn')}</b></span></div>
                        <div style={{ margin: '0 0 20px 0' }}><span></span><b>{t('FrameworkContract.last')}</b></div>
                        <div className="offerta_body">
                            <div className="offerta_number">1</div>
                            <div className="offerta_title"><b>{t('FrameworkContract.ContractSubject.contractSubject')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>1.1</span>
                                    <p>
                                        {t('FrameworkSupplyContract.ContractSubject.first')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>1.1.1</span>
                                    <p>
                                        {t('FrameworkSupplyContract.ContractSubject.second')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>1.1.2</span>
                                    <p>
                                        {t('FrameworkSupplyContract.ContractSubject.third')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">2</div>
                            <div className="offerta_title"><b>{t('FrameworkContract.GeneralProvisions.generalProvisions')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>2.1</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.first')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.2</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.second')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.3</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.third')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.3.1</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.fourth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.3.2</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.fifth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.3.3</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.sixth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.3.4</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.seventh')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.3.5</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.eighth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>2.3.6</span>
                                    <p>
                                        {t('FrameworkSupplyContract.GeneralProvisions.ninth')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">3</div>
                            <div className="offerta_title">
                                <b>
                                    {t('FrameworkContract.Rules.rules')}
                                </b>
                            </div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>3.1</span>
                                    <p>
                                        <b>{t('FrameworkSupplyContract.Rules.header')}</b>
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>3.1.1</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.first')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>3.1.2</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.second')}
                                    </p>
                                </div>

                                <div className="offerta_text_items">
                                    <span>3.1.3</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.third')}
                                    </p>
                                </div>

                                <div className="offerta_text_items">
                                    <span>3.1.4</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.fourth')}
                                    </p>
                                </div>

                                <div className="offerta_text_items">
                                    <span>3.1.5</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.fifth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>3.2</span>
                                    <p>
                                        <b>{t('FrameworkSupplyContract.Rules.sixth')}</b>
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>3.2.1</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.seventh')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>3.2.2</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.eighth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>3.3</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Rules.ninth')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">4</div>
                            <div className="offerta_title"><b>{t('FrameworkContract.Settlement.settlement')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>4.1</span>
                                    <p>
                                        {t('FrameworkContract.Settlement.first')}
                                        <b>{t('FrameworkContract.Settlement.firstLast')}</b>
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>4.2</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Settlement.second')}
                                    </p>
                                </div>

                                <div className="offerta_text_items">
                                    <span>4.3</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Settlement.third')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>4.4</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Settlement.fourth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>4.5</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Settlement.fifth')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>4.6</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Settlement.sixth')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">5</div>
                            <div className="offerta_title"><b>{t('FrameworkSupplyContract.Responsibility.responsibility')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>5.1</span>
                                    <p>
                                        {t('FrameworkSupplyContract.Responsibility.first')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">6</div>
                            <div className="offerta_title"><b>{t('FrameworkContract.DisputeResolution.disputeResolution')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>6.1</span>
                                    <p>
                                        {t('FrameworkContract.DisputeResolution.first')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">7</div>
                            <div className="offerta_title"><b>{t('FrameworkContract.ForceMajeure.forceMajeure')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>7.1</span>
                                    <p>
                                        {t('FrameworkContract.ForceMajeure.first')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>7.2</span>
                                    <p>
                                        {t('FrameworkContract.ForceMajeure.second')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">8</div>
                            <div className="offerta_title"><b>{t('FrameworkContract.Duration.duration')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>8.1</span>
                                    <p>
                                        {t('FrameworkContract.Duration.first')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>8.2</span>
                                    <p>
                                        {t('FrameworkContract.Duration.second')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>8.3</span>
                                    <p>
                                        {t('FrameworkContract.Duration.third')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="offerta_body">
                            <div className="offerta_number">9</div>
                            <div className="offerta_title"><b>{t('FrameworkContract.Other.other')}</b></div>
                            <div className="offerta_text">
                                <div className="offerta_text_items">
                                    <span>9.1</span>
                                    <p>
                                        {t('FrameworkContract.Other.first')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>9.2</span>
                                    <p>
                                        {t('FrameworkContract.Other.second')}
                                    </p>
                                </div>
                                <div className="offerta_text_items">
                                    <span>9.3</span>
                                    <p>
                                        {t('FrameworkContract.Other.third')}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <div className="offerta_number"><b>10</b></div>
                            <div><b>{t('FrameworkContract.Contacts.contacts')}</b></div>
                        </div>
                        <div style={{ width: '100%', display: 'flex' }}>
                            <div className="framework_Contract_footer_items">
                                <span><b>{t('FrameworkSupplyContract.buyer')}:</b></span>
                                <span style={{ marginBottom: '30px' }}>{t('FrameworkContract.Contacts.legalAddress')}</span>
                                <span>{t('invoice.inn')}</span>
                                <span>{t('FrameworkContract.Contacts.kpp')}</span>
                                <span>{t('respond.phoneNumber')}</span>
                            </div>
                            <div className="framework_Contract_footer_items">
                                <span><b>{t('FrameworkSupplyContract.supplier')}:</b></span>
                                <span style={{ marginBottom: '30px' }}>{t('FrameworkContract.Contacts.legalAddress')}</span>
                                <span>{t('invoice.inn')}</span>
                                <span>{t('FrameworkContract.Contacts.kpp')}</span>
                                <span>{t('respond.phoneNumber')}</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between', margin: '40px 0 40px 0' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <b><span>__________________</span></b>
                                <b><span>{t('FrameworkSupplyContract.buyer')}</span></b>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <b><span>__________________</span> </b>
                                <b><span>{t('FrameworkSupplyContract.supplier')}</span></b>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FrameworkSupplyContract;