import React from "react";
import ArrowSVG from "../../../../assets/icon/arrowLong.svg";
import WarningSVG from "../../../../assets/icon/warning.svg";
import MarkSVG from "../../../../assets/icon/grayMark.svg";
import "moment/locale/ru";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteRequestTrade,
  fetchOffer,
  fetchRequests,
  startTrade,
} from "../offerSlice";
import {Button, Modal} from "antd";
import { useHistory } from "react-router-dom";
import history from "../../../../utils/history";

function RespondingUser({ data }) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [customerName, setCustomerName] = useState("");
  const [isModalVisible, setModalVisible] = useState(false);
  const [isWarningModalVisible, setWarningModalVisible] = useState(false);
  const acceptRequest = () => {
    if (data.hasMoney) {
      dispatch(
        startTrade({ offerId: data.offerId, requestId: data.requestId })
      ).then((params) => {
          history.push('/trades')
        // dispatch(fetchOffer({ id: data.offerId }));
      });
    } else {
      setModalVisible(true);
    }
  };
  const deleteRequest = () => {
    dispatch(deleteRequestTrade({ id: data.requestId })).then((params) => {
      dispatch(fetchOffer({ id: data.offerId }));
      dispatch(fetchRequests({ id: data.offerId }));
    });
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  const warningModalClose = () => {
    setWarningModalVisible(false)
  }
  const handleCallWarning = () => {
    setWarningModalVisible(true)
  }
  const handleGoBack = () => {
      history.goBack()
  }
  const handleOk = () => {
    history.push({
      pathname: "/wallet",
      state: { price: data.price },
    });
  };
  useEffect(() => {
    if (data.beneficiaryData) {
        setWarningModalVisible(true)
      let customerData = data.beneficiaryData;
      setCustomerName(
        customerData.name
          ? customerData.name
          : `${customerData.last_name} ${customerData.first_name} ${customerData.middle_name}`
      );
    } else {
      setCustomerName("customer");
    }
  }, [data]);

  // const formatDate = moment(date).format('DD MMM YYYY')

  return (
    <div style={{ cursor: "pointer" }} className="card">
      <span className="card_header">
        <div
          style={{ display: "flex", alignItems: "center" }}
          className="offer-number"
        >
          {customerName}
        </div>
        <span
          style={{ display: "flex", alignItems: "center" }}
          className="date"
        >
          {moment(data.createdAt).local().format("H:mm, DD.MM.YYYY")}
          {/* <button style={{ display: 'flex', alignItems: 'center', padding: 3 }} className="no-styles">
                        <MessageIco style={{ height: 15, marginLeft: 5, opacity: 0.5 }} />  </button> */}
        </span>
      </span>
      <h2 className="card_title">{t("respond.supplier")}</h2>
      <div className="card_footer">
        <span className="card_info">
          <span className="card_info_item">
            <span>{t("invoice.inn")}: </span> {data.inn}
          </span>
          <span className="card_info_item">
            <span>{t("respond.phoneNumber")}: </span>
            <a style={{ color: "#615ffb" }} href={`tel:${data.phoneNum}`}>
              {data.phoneNum}
            </a>
          </span>
          <span className="card_info_item">
            <span>{t("Applications.route")}: </span> {data.upload}
            <img
              style={{ margin: "0 2px", height: 19, width: 33 }}
              src={ArrowSVG}
              alt=""
            />{" "}
            {data.unload}
          </span>
          {/* <span className="card_info_item"><span>{t('NewApplication.customerContacts')}: </span> {'Не указан'}</span> */}
          <span className="card_info_item">
            <span>{t("Applications.price")}: </span> <span> {data.price}р</span>
          </span>
        </span>
        <div className="card_btn-container">
          <button onClick={deleteRequest} className="btn gray">
            {t("respond.reject")}
          </button>
          <button onClick={acceptRequest} className="btn">
            {t("respond.accept")}
          </button>
        </div>
      </div>
      <Modal
        closeIcon={<div className="close">{t("NewApplication.close")}</div>}
        title={t("carryOutWarning")}
        okText={t("Wallet.verify")}
        cancelText={t("NewApplication.rejection")}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      ></Modal>
      <Modal
          closeIcon={<div className="close">{t("NewApplication.close")}</div>}
          title={
          <div className='warning-modal-subtitle'>
              <img style={{marginRight:'10px'}} src={WarningSVG} alt=""/>
              {t("WarningModal.header")}
          </div>}
          visible={isWarningModalVisible}
          onCancel={warningModalClose}
          footer={[
              <div>
                <Button key='submit'
                        className='btn'
                        onClick={warningModalClose}
                        style={{backgroundColor: '#4FAE70', maxWidth: '292px', marginBottom: '18px', height: '62px'}}>{t("WarningModal.button")}</Button>
              </div>,
              <div>
                <a className='warning-goback-button' onClick={handleGoBack}>{t("WarningModal.goBack")}</a>
              </div>
          ]}
      >
        <div className='warning-modal-content'>
          <span className='warning-modal-item'>
              <div>
                  <img className='warning-modal-img' src={MarkSVG} alt=""/>
              </div>
              {t("WarningModal.firstPoint")}
          </span>
          <span className='warning-modal-item'>
              <div>
                  <img className='warning-modal-img' src={MarkSVG} alt=""/>
              </div>
              {t("WarningModal.secondPoint")}
          </span>
          <span className='warning-modal-item'>
              <div>
                  <img className='warning-modal-img' src={MarkSVG} alt=""/>
              </div>
              {t("WarningModal.thirdPoint")}
          </span>
            <span className='warning-modal-title'>{t("WarningModal.attention")}</span>
        </div>
      </Modal>
    </div>
  );
}

export default RespondingUser;
