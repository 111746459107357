import React from 'react'

import 'moment/locale/ru'
import { useTranslation } from 'react-i18next'
import notFound from '../../assets/icon/notFound.svg'
import './notFound.css'
import {Helmet} from "react-helmet";

function NotFound(props) {
  const { t } = useTranslation()
  return (
    <div className="notFound">
        <Helmet>
            <title>{t('Not found')}</title>
        </Helmet>
      <div className='content-wrapper'>
        <div className='notFound-wrapper'>
          <img src={notFound} alt="" />
          <div className='notFound_subtitle'>{t('notFound')} <a style={{ color: "#615FFB", textDecoration: 'underline' }} href='/'>{t('notFoundSup')}</a></div>
        </div>
      </div>
    </div>
  )
}

export default NotFound
