// import axios from 'axios';
// import { getConfig } from '../configs/config';
// import Keycloak from 'keycloak-js';
//
// let defaultOptions = {
//   baseURL: getConfig().baseUrl + '/api/v1/',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// };
//
// let chatOptions = {
//   baseURL: 'https://tonna.io/api/chat',
//   headers: {
//     'Content-Type': 'application/json',
//   },
// };
//
// export const searchProviderConf = {
//   searchUrl: getConfig().baseUrl + '/nominatim/search',
//   reverseUrl: getConfig().baseUrl + '/nominatim/reverse',
// };
//
// export const api = axios.create(defaultOptions);
// export const chatApi = axios.create(chatOptions);
//
// // let redirectUrl = window.location.href;
//
// // Создание экземпляра Keycloak
// const keycloak = new Keycloak({
//   url: 'https://auth.tonna.io/auth',
//   realm: 'tonna_realm',
//   clientId: 'tonna_client',
// });
// const urlParams = new URLSearchParams(window.location.search);
// const isLoginRedirect = urlParams.has('keycloak=true');
// // Функция для обработки успешного получения токена
// function handleToken(token) {
//   // Сохранение токена в Redux (пример)
//   console.log('token', token);
//
//   // Выполнение запросов с использованием токена
//   // api.get('/protected-endpoint', {
//   //   headers: {
//   //     Authorization: `Bearer ${token}`,
//   //   },
//   // })
//   //     .then((response) => {
//   //       // Обработка успешного ответа
//   //     })
//   //     .catch((error) => {
//   //       console.log('error', error);
//   //       // Обработка ошибки
//   //     });
// }
//
// // Функция для обработки ошибок
// function handleError(error) {
//   console.error('Ошибка при получении токена Keycloak:', error);
// }
//
// // Инициализация Keycloak
// if (!isLoginRedirect) {
//     keycloak.init({ onLoad: 'login-required' })
//         .then((authenticated) => {
//             if (authenticated) {
//                 // Пользователь авторизован, можно получить токен
//
//                 // Получение токена доступа
//                 const token = keycloak.token;
//
//                 // Обработка успешного получения токена
//                 handleToken(token);
//             } else {
//                 // Пользователь не авторизован, перенаправление на страницу авторизации Keycloak
//                 window.location.href = `${getConfig().baseUrl}/oauth2/authorization/keycloak?keycloak=true`;
//             }
//         })
//         .catch((error) => {
//             // Обработка ошибки инициализации Keycloak
//             handleError(error);
//         });
// }


import axios from 'axios';
import { getConfig } from '../configs/config';
import {setToken} from "../components/Home/homeSlice";

let defaultOptions = {
    baseURL: getConfig().baseUrl + '/api/v1/',
    headers: {
        'Content-Type': 'application/json',
    },
};
let whoAmI = {
    baseURL: getConfig().baseUrl + '/api',
    headers: {
        'Content-Type': 'application/json',
    },
}
let chatOptions = {
    baseURL: 'https://tonna.io/api/chat',
    headers: {
        'Content-Type': 'application/json',
    },
}

let profileOptions = {
    baseURL: 'http://profile.tonna.io:8088/api/v1',
    headers: {
        'Content-Type': 'application/json',
    },
}

export const searchProviderConf = {
    searchUrl: getConfig().baseUrl + '/nominatim/search',
    reverseUrl: getConfig().baseUrl + '/nominatim/reverse'
}


export const api = axios.create(defaultOptions);
export const chatApi = axios.create(chatOptions)
export const whoAmIApi = axios.create(whoAmI)
export const profileApi=axios.create(profileOptions)

// api.interceptors.request.use(function (config) {
//     const username = 'user';
//     const password = 'password';
//     const credentials = btoa(username + ':' + password);
//     const basicAuth = 'Basic ' + credentials;
//     localStorage.setItem('basetoken', basicAuth)
//     const basicAuthEnabled = getConfig().basicAuthEnabled
//     if (basicAuthEnabled) {
//         config.headers.Authorization = basicAuth;
//     }
//     return config;
// }, function (error) {
//     // Do something with request error
//     console.log('error', error);
//     return Promise.reject(error);
// });

api.interceptors.response.use(
    function (response) {
        // console.log(response)
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        if(error.response.status === 401 && error.response.config.url.startsWith("private") )
            window.location.href = `${getConfig().baseUrl}/oauth2/authorization/keycloak`
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        // Cookie: i18next=ru; _gid=GA1.2.818083032.1648299390; JSESSIONID=88E29F315F68A98D75CC443891CDD0FD; XSRF-TOKEN=f420e0e0-6167-449c-a023-46aa5c5456f3; _ga_5ZL1N2C16K=GS1.1.1648325039.29.1.1648326402.0; _ga=GA1.2.849344583.1642867140
        return Promise.reject(error);
    }


);


// chatApi.interceptors.request.use(function (config) {
//     const username = 'user';
//     const password = 'password';
//     const credentials = btoa(username + ':' + password);
//     const basicAuth = 'Basic ' + credentials;
//     localStorage.setItem('basetoken', basicAuth)
//     const basicAuthEnabled = getConfig().basicAuthEnabled
//     if (basicAuthEnabled) {
//         config.headers.Authorization = basicAuth;
//     }
//     return config;
// }, function (error) {
//     // Do something with request error
//     console.log('error', error);
//     return Promise.reject(error);
// });
// chatApi.interceptors.response.use(
//     function (response) {
//         // console.log(response)
//         // Any status code that lie within the range of 2xx cause this function to trigger
//         // Do something with response data
//         return response;
//     }, function (error) {
//         if(error.response.status === 401 && error.response.config.url.startsWith("private") )
//             window.location.href = `${getConfig().baseUrl}/oauth2/authorization/keycloak`
//         // Any status codes that falls outside the range of 2xx cause this function to trigger
//         // Do something with response error
//
//         // Cookie: i18next=ru; _gid=GA1.2.818083032.1648299390; JSESSIONID=88E29F315F68A98D75CC443891CDD0FD; XSRF-TOKEN=f420e0e0-6167-449c-a023-46aa5c5456f3; _ga_5ZL1N2C16K=GS1.1.1648325039.29.1.1648326402.0; _ga=GA1.2.849344583.1642867140
//         return Promise.reject(error);
//     }
//
//
// );

// whoAmIApi.interceptors.request.use(function (config) {
//     const username = 'user';
//     const password = 'password';
//     const credentials = btoa(username + ':' + password);
//     const basicAuth = 'Basic ' + credentials;
//     localStorage.setItem('basetoken', basicAuth)
//     const basicAuthEnabled = getConfig().basicAuthEnabled
//     if (basicAuthEnabled) {
//         config.headers.Authorization = basicAuth;
//     }
//     return config;
// }, function (error) {
//     // Do something with request error
//     console.log('error', error);
//     return Promise.reject(error);
// });
// whoAmIApi.interceptors.response.use(
//     function (response) {
//         // console.log(response)
//         // Any status code that lie within the range of 2xx cause this function to trigger
//         // Do something with response data
//         return response;
//     }, function (error) {
//         if(error.response.status === 401)
//             window.location.href = `${getConfig().baseUrl}/oauth2/authorization/keycloak`
//         // Any status codes that falls outside the range of 2xx cause this function to trigger
//         // Do something with response error
//
//         // Cookie: i18next=ru; _gid=GA1.2.818083032.1648299390; JSESSIONID=88E29F315F68A98D75CC443891CDD0FD; XSRF-TOKEN=f420e0e0-6167-449c-a023-46aa5c5456f3; _ga_5ZL1N2C16K=GS1.1.1648325039.29.1.1648326402.0; _ga=GA1.2.849344583.1642867140
//         return Promise.reject(error);
//     }
//
//
// );
