import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api} from "../../Store/api";
import {fetchOffer} from "../Offers/Offer/offerSlice";


export const fetchNotifications = createAsyncThunk(
    'notificationsSlice/fetchNotifications',
    async () => {
        const response = await api.get(`private/push/messages`)
        return response.data
    }
)

export const markAsRead = createAsyncThunk(
    'notificationsSlice/markAsRead',
    async () => {
        const response = await api.post(`private/push/messages/all`)
        return response.data
    }
)

const initialState = () => (
    {
        loading: false,
        lastNotification: '',
        notifications: null,
        error: {
            failed: false,
            message: null,
        }
    }
)

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState(),
    extraReducers: {
        [fetchNotifications.pending]: (state) => {
            state.loading = true
            state.error.failed = false
            state.error.message = null
        },
        [fetchNotifications.fulfilled]: (state, action) => {
            state.loading = false
            state.notifications = action.payload
        },
        [fetchNotifications.rejected]: (state, action) => {
            state.loading = false
            state.error.failed = true
            state.error.message = action.payload
        }
    }
})

export const { setNotification } = notificationsSlice.actions

export default notificationsSlice.reducer

