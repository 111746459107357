import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../../Store/api'
// import { newRequestFormSend } from '../../../RequestsManager/NewRequest/newRequestSlice'
import { message } from 'antd'
import keycloak from "../../../../Keycloak";

export const generalMapDataSend = createAsyncThunk(
  'selectLocation/sendGeneralMapData',
  async (data) => {
    api.defaults.headers = {
      Authorization: `Bearer ${keycloak.token}`
    }
    try {
      const response = await api.post('private/cargo_offer', data.markedPlace)
      return response.data
    } catch (e) {
      throw e
    }
  }
)
export const fetchGeneralMapData = createAsyncThunk(
  'selectLocation/fetchGeneralMapData',
  async () => {
    const response = await api.get('private/cargo_offers')
    return response.data
  }
)
const initialState = () => {
  return {
    adding: false,
    loading: false,
    searchVal: '',
    cars: [],
    selectedLocation: {
      coords: { lat: 55.751174, lng: 37.612253 },
      name: '',
    },
    selectedPlace:
    {
      coords: {
        lat: 55.7522, lng: 37.6156
      }
    },
    error: {
      failed: false,
      message: null,
    },
  }
}

const selectLocationSlice = createSlice({
  name: 'selectLocation',
  initialState: initialState(),

  reducers: {
    setLocation(state, action) {
      state.selectedLocation = action.payload
    },
    changeSearchVal(state, action) {
      state.searchVal = action.payload
    },
    setPlace(state, action) {
      state.selectedPlace = action.payload
    }
  },
  extraReducers: {
    [generalMapDataSend.pending]: (state) => {
      state.adding = true
      state.error.failed = false
      state.error.message = null
    },
    [generalMapDataSend.fulfilled]: (state, action) => {
      message.success({
        content: 'Транспорт успешно отмечен!',
        style: {
          marginTop: '17vh'
        }
      })
      state.adding = false
    },
    [generalMapDataSend.rejected]: (state, action) => {
      // message.error({
      //   content: 'Что-то пошло не так....',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload
      state.error.failed = true
    },
    [fetchGeneralMapData.pending]: (state) => {
      state.loading = true
      state.error.failed = false
      state.error.message = null
    },
    [fetchGeneralMapData.fulfilled]: (state, action) => {
      state.loading = false
      state.cars = action.payload
    },
    [fetchGeneralMapData.rejected]: (state, action) => {
      // message.error({
      //   content: 'Что-то пошло не так....',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload
      state.error.failed = true
    }
  }

})
export const { setLocation, changeSearchVal, setPlace } = selectLocationSlice.actions
export default selectLocationSlice.reducer


