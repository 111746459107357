import React from 'react'
import './subscriptions.css'
import Start from "./Start/Start";
import Nitro from "./Nitro/Nitro";
import Pro from "./Pro/Pro";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet";
import ToggleSwitch from "./ToggleSwitch/ToggleSwitch";
import BeneficiaryCard from "./BeneficiaryCard/BeneficiaryCard";

function Subscriptions() {
    const {t} = useTranslation()


    return (
        <section className="Subscriptions main">
            <div className="content-wrapper"  style={{alignItems: 'center'}}>
                <BeneficiaryCard/>
                <Helmet>
                    <title>{t('Subscriptions.PageTitle')}</title>
                </Helmet>
                <h1 className="title mobileMargin">{t('Subscriptions.PageTitle')}</h1>
                <div style={{maxWidth: '599px', marginBottom: '60px'}} className='subscription_description'>
                    {t('Subscriptions.pageDescription')}
                </div>
                <ToggleSwitch/>
                <div className='subscription_card_container'>
                    <Start active />
                    <Nitro/>
                    <Pro />
                </div>
            </div>
        </section>
    )
}

export default Subscriptions
