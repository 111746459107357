import React from 'react';
import ActiveCheck from '../../../../assets/newLanding/rates/activeCheck.svg'
import {useTranslation} from "react-i18next";

const Start = ({active,disabled}) => {
    const {t} = useTranslation()
    return (
        <div className="subscription_card" style={{opacity:disabled ? "0.3" : null}}>
            <div className='subscription_title_container'>
                <span className="subscription_card_title">Start</span>
                {active && !disabled && <div className='subscription_active_status'>{t('Subscriptions.active')}
                    <img src={ActiveCheck} alt={''}/>
                </div>}
            </div>
            {/*<div className='subscription_subtitle_container'>*/}
            {/*    /!*<div className='subscription_card_subtitle'>{t("Lending.Free")}</div>*!/*/}
            {/*    /!*<div className='subscription_card_chose'>*!/*/}
            {/*    /!*    <img src={choseIcon} alt=""/>*!/*/}
            {/*    /!*    <span*!/*/}
            {/*    /!*        style={{*!/*/}
            {/*    /!*            marginLeft: '24px',*!/*/}
            {/*    /!*            fontSize: '20px',*!/*/}
            {/*    /!*        }}>{t('Subscriptions.Start.yourChoice')}</span>*!/*/}
            {/*    /!*</div>*!/*/}
            {/*</div>*/}
            <div className='subscription_card_content'>
                <div className='subscription_card_about'>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>10% {t('Subscriptions.Nitro.first')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>1 {t('Subscriptions.Start.second')}</span>
                        </div>
                    </div>
                    <div className='subscription_about_block'>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.third')}</span>
                        </div>
                        <div className='about_items_container'>
                            {/*<img src={markImg} alt=""/>*/}
                            <span>{t('Subscriptions.Nitro.fourth')}</span>
                        </div>
                    </div>
                </div>
            </div>
            {!disabled && <div className='subscription_card_subtitle'>{'12 ноября — последний день подписки'}</div>}
        </div>
    );
};

export default Start;