import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    url: "https://auth.tonna.io/auth",
    realm: "tonna_realm",
    clientId: "tonna_client",
});

export default keycloak

// export function getToken() {
//     return new Promise((resolve, reject) => {
//         keycloak.init({ onLoad: 'login-required' }).then((authenticated) => {
//             if (authenticated) {
//                 const token = keycloak.token;
//                 resolve(token);
//             } else {
//                 reject(new Error('Failed to authenticate user with Keycloak'));
//             }
//         }).catch((error) => {
//             reject(error);
//         });
//     });
// }
