import { Spin } from 'antd'
import React from 'react'
import { LoadingOutlined } from '@ant-design/icons';

const Loader = () => {
    const antIcon = <LoadingOutlined style={{ fontSize: 40, color: '#615ffb' }} spin />
    return (
        <div className='Loader'><Spin indicator={antIcon} size="large" /></div>
    )
}
export default Loader