import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import ProPayment from '../../components/Subscriptions/Pro/ProPayment';
import NitroPayment from '../../components/Subscriptions/Nitro/NitroPayment';
import { useReactToPrint } from 'react-to-print';
import { prepareClassName } from '../../../utils/styles';
import { GetInvoiceNumber } from '../../components/GetInvoiceNumber';
import { increaseInvoiceNumber } from '../../components/Subscriptions/subscriptionsSlice';
import yourRateIcon from '../../../assets/newLanding/rates/yourRateIcon.svg';
import styles from './styles.module.css';
import ToggleSwitch from "../../components/Subscriptions/ToggleSwitch/ToggleSwitch";

const SELECT_BUTTON_MODE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
};

const Select = (props) => {

};

const SelectButton = (props)  => {
    const { children, mode, onClick, disabled } = props;
    const isPrimary = mode === SELECT_BUTTON_MODE.PRIMARY;

    const className = prepareClassName([
        styles.button,
        isPrimary && styles.buttonPrimary,
    ]);

    return (
        <button className={className} onClick={onClick} disabled={disabled}>{children}</button>
    );
};

const Rate = (props) => {
    const { title, children, price, oldPrice, titleColor, isYourRate, isSelectDisabled, isPrimary, onClick } = props;
    const { t } = useTranslation();

    const rootClassName = prepareClassName([styles.rate, isPrimary && styles.ratePrimary]);
    const priceClassName = prepareClassName([styles.price, isPrimary && styles.pricePrimary]);

    return (
        <div className={rootClassName}>
            <div className={styles.rateHeader}>
                <div className={styles.rateTitle} style={{ color: titleColor }}>{title}</div>
                {isYourRate && (
                    <div className={styles.selectedMark}>
                        <div className={styles.selectedMarkText}>{t('Subscriptions.Start.yourChoice')}</div>
                        <img className={styles.selectedMarkIcon} src={yourRateIcon} alt="" />
                    </div>
                )}
            </div>
            <div className={styles.rateText}>
                {children}
            </div>
            <div className={styles.bottom}>
                {(price || oldPrice) && (
                    <div className={priceClassName}>
                        {oldPrice && (
                            <div className={styles.priceItem}>
                                <div className={styles.oldPrice}>
                                    {oldPrice.toLocaleString()}
                                </div>
                                <div>₽</div>
                            </div>
                        )}
                        {price && (
                            <div className={styles.priceItem}>
                                <div className={styles.currentPrice}>
                                    {price.toLocaleString()}
                                </div>
                                <div>₽/{t('Subscriptions.year')}</div>
                            </div>
                        )}
                    </div>
                )}
                <SelectButton
                    onClick={onClick}
                    mode={isPrimary ? SELECT_BUTTON_MODE.PRIMARY : SELECT_BUTTON_MODE.SECONDARY}
                    disabled={isSelectDisabled}
                >
                    {t('Subscriptions.btn')}
                </SelectButton>
            </div>
        </div>
    )
};

const PRINT_NITRO_SELECTOR = '.print_nitro';
const PRINT_RPO_SELECTOR = '.print_pro';

const Rates = () => {
    const { t } = useTranslation()
    const invoice = useSelector(state => state.walletSlice.wallet.invoice)
    const invoiceNumber = useSelector(state => state.subscriptionsSlice.invoiceNumber)
    const dispatch = useDispatch();

    const printNitroRate = useReactToPrint({
        content: () => document.querySelector(PRINT_NITRO_SELECTOR),
        documentTitle: `Счет на оплату подписки TONNA Nitro ${moment().format('DD.MM.yyyy')}`
    });

    const printProRate = useReactToPrint({
        content: () => document.querySelector(PRINT_RPO_SELECTOR),
        documentTitle: `Счет на оплату подписки TONNA Pro ${moment().format('DD.MM.yyyy')}`
    });

    const handleSelectNitro = () => {
        printNitroRate();
        dispatch(increaseInvoiceNumber());
    };

    const handleSelectPro = () => {
        printProRate();
        dispatch(increaseInvoiceNumber());
    };

    return (
        <section className={styles.wrapper}>
            <div className={styles.titleWrapper}>
                <SecondaryTitle>{"Подписки"}</SecondaryTitle>
            </div>
            <ToggleSwitch/>
            <div className={styles.listWrapper}>
                <Rate title="Start" isYourRate isSelectDisabled>
                    <p>{t('Subscriptions.Nitro.fourth')}</p>
                    <p>5% {t('Subscriptions.Nitro.first')}</p>
                    <p>1 {t('Subscriptions.Start.second')}</p>
                    <p>{t('Subscriptions.Nitro.third')}</p>
                </Rate>
                <Rate title="Nitro" price={28500} oldPrice={57000} isPrimary onClick={handleSelectNitro} titleColor="#45A484">
                    <p>4% {t('Subscriptions.Nitro.first')}</p>
                    <p>10 {t('Subscriptions.Nitro.second')}</p>
                    <p>{t('Subscriptions.Nitro.third')}</p>
                    <p>{t('Subscriptions.Nitro.fourth')}</p>
                    <p>{t('Subscriptions.Nitro.fifth')} {t('Subscriptions.Nitro.fifthStrong')}</p>
                </Rate>
                <Rate title="Pro" price={91200} oldPrice={228000} onClick={handleSelectPro} titleColor="#D95D06">
                    <p>3% {t('Subscriptions.Nitro.first')}</p>
                    <p>30 {t('Subscriptions.Nitro.second')}</p>
                    <p>{t('Subscriptions.Nitro.third')}</p>
                    <p>{t('Subscriptions.Nitro.fourth')}</p>
                    <p>{t('Subscriptions.Nitro.fifth')} {t('Subscriptions.Pro.fifthStrong')}</p>
                    <p>{t('Subscriptions.Nitro.sixth')}</p>
                    <p>{t('Subscriptions.Pro.first')}</p>
                    <p>{t('Subscriptions.Pro.second')}</p>
                </Rate>
            </div>

            <ProPayment token={invoice?.invoice_uuid} invoiceNumber={GetInvoiceNumber(invoiceNumber)} />
            <NitroPayment token={invoice?.invoice_uuid} invoiceNumber={GetInvoiceNumber(invoiceNumber)} />
        </section>
    );
};

export default Rates;
