import React, { useEffect, useRef, useState } from 'react';
import {NavLink, Link} from 'react-router-dom'
import {ReactComponent as HomeSVG} from '../../../assets/icon/home.svg'
import {ReactComponent as DocumentSVG} from '../../../assets/icon/document.svg'
import {ReactComponent as MapSVG} from '../../../assets/icon/map.svg'
import {ReactComponent as WalletSVG} from '../../../assets/icon/wallet.svg'
import {ReactComponent as MenuSVG} from '../../../assets/icon/menu.svg'
import {ReactComponent as SubscriptionsSVG} from '../../../assets/icon/crown.svg'
import {ReactComponent as NewOfferSVG} from '../../../assets/icon/plus.svg'
import {ReactComponent as PartnersSVG} from '../../../assets/icon/partners.svg'
import {ReactComponent as NotificationsSVG} from '../../../assets/icon/notifications.svg';
import {ReactComponent as ChatSVG} from '../../../assets/icon/chat.svg';
import {ReactComponent as ExitSVG} from '../../../assets/icon/Exit.svg'
import './menu.css'
import {useTranslation} from "react-i18next";
import { useAuth } from '../../../contexts/authContext';
import { logoutUser } from '../../../utils/auth';

function Menu(props) {
    const {t} = useTranslation()
    const [isMenuShow, setIsMenuShow] = useState(false);
    const optionsWrapperRef = useRef(null);
    const { isAuth } = useAuth();

    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                setIsMenuShow(false);
            }
        };

        const handleClick = (event) => {
            if (optionsWrapperRef.current && event.target && !optionsWrapperRef.current.contains(event.target)) {
                setIsMenuShow(false);
            }
        };

        window.addEventListener('keydown', handleEscKey);
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('keydown', handleEscKey);
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const handleButtonClick = (event) => {
        event.stopPropagation();
        setIsMenuShow((prev) => !prev);
    };

    return (
        <div className="menu">
            <NavLink className="item" activeClassName="menu_item-active" exact to="/">
                <HomeSVG className="menu-svg"/>
            </NavLink>
            <NavLink className="item" activeClassName="menu_item-active" to="/offers/">
                <DocumentSVG className="menu-svg"/>
            </NavLink>
            <NavLink className="item" activeClassName="menu_item-active" to="/chats/">
                <ChatSVG className="menu-svg"/>
            </NavLink>
            <NavLink className="item" activeClassName="menu_item-active" to="/map">
                <MapSVG className="menu-svg"/>
            </NavLink>
            <button onClick={handleButtonClick} className="menu_item" style={{content: "none", background: "none"}}>
                <MenuSVG style={{height: 25}} className="menu-svg"/>
                {isMenuShow && (
                    <div className="sub-menu" ref={optionsWrapperRef}>
                        <NavLink className='item' activeClassName='menu_item-active' exact to='/notifications'>
                            <NotificationsSVG style={{width: '24px', height: '22px'}} className='menu-svg'/>
                            <span>{t("Menu.notifications")}</span>
                        </NavLink>
                        <NavLink className="item" activeClassName="menu_item-active" to="/wallet">
                            <WalletSVG className="menu-svg"/>
                            <span>{t('Menu.wallet')}</span>
                        </NavLink>
                        <NavLink className="item" activeClassName="menu_item-active" to="/newoffer">
                            <NewOfferSVG className="offer-svg"/>
                            <span>{t('Menu.newRequest')}</span>
                        </NavLink>
                        <NavLink className="item" activeClassName="menu_item-active" to="/subscriptions">
                            <SubscriptionsSVG className="menu-svg"/>
                            <span>{t('Menu.subscriptions')}</span>
                        </NavLink>
                        {/*<Link className="item" activeClassName="menu_item-active"*/}
                        {/*      to={{pathname: "http://partners.tonna.io"}} target="_blank">*/}
                        {/*    <PartnersSVG className="menu-svg"/>*/}
                        {/*    <span>{t('Menu.partners')}</span>*/}
                        {/*</Link>*/}
                        {isAuth && (
                            <div className='item' onClick={logoutUser}>
                                <ExitSVG className='menu-svg'/>
                                <span>{t('Lending.exit')}</span>
                            </div>
                        )}

                        {/*<span className="sub-menu-item">{t('Applications.waiting')}<div className="circle"></div></span>*/}
                        {/*<span className="sub-menu-item">{t('Applications.applicationManagement')}<div className="circle"></div></span>*/}
                    </div>
                )}
            </button>
        </div>
    )
}

export default Menu
