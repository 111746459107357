import React, {useEffect} from 'react'
import {Input, Select, Tooltip} from 'antd'
import {QuestionCircleOutlined, SearchOutlined} from '@ant-design/icons'
import { NavLink } from 'react-router-dom'
import PublicOffers from './PublicOffers/PublicOffers'
import { ReactComponent as ArrowSVG } from '../../../src/assets/icon/arrow-price-filter.svg'
import history from "../../utils/history";
import { Route, Router } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './offers.css'
import MyOffers from './MyOffers/MyOffers'
import { useDispatch } from 'react-redux'
import { useState } from 'react'
import {filterByDate, filterByPrice, searchFilter} from './offersSlice';
import MyTrades from './MyTrades/MyTrades'
import {Helmet} from "react-helmet";

function Offers() {
  const { t } = useTranslation()

  const [angal, setAngal] = useState(180)
  const [angalDate, setAngalDate] = useState(180)
  const [filterPriceFromLow, setFilterPriceFromLow] = useState(false)
  const [filterDateFromLow, setFilterDateFromLow] = useState(false)
  const dispatch = useDispatch()
  const filterClickHandlerDate = () => {
    setFilterDateFromLow(!filterDateFromLow)
    setAngalDate(angalDate + 180)
    dispatch(filterByDate(filterDateFromLow))
  }

  const filterClickHandler = () => {
    setFilterPriceFromLow(!filterPriceFromLow)
    setAngal(angal + 180)
    dispatch(filterByPrice(filterPriceFromLow))
  }
  const changeSearchValue = (e) => {
    dispatch(searchFilter(e.target.value))
  }


  return (
    <section className='Offers main'>
      <div className='content-wrapper'>
        <div style={{ display: 'flex' }}>
          <Helmet>
            <title>{t("Applications.applicationManagement")}</title>
          </Helmet>
          <h1 className='title'>{t("Applications.applicationManagement")}</h1>
          <Tooltip title={t('Applications.tooltip')}>
            <QuestionCircleOutlined style={{ marginLeft: '10px', marginTop: '11px' }} />
          </Tooltip>
        </div>
        <div className='offers_menu'>
          <NavLink activeClassName='sub_menu_item-active' exact to='/offers' rel="canonical" className='sub_menu_item'>{t('Applications.allRequests')}</NavLink>
          <NavLink activeClassName='sub_menu_item-active' to='/offers/my' className='sub_menu_item'>{t("Applications.applications")}</NavLink>
          <NavLink activeClassName='sub_menu_item-active' to='/trades' rel="canonical" className='sub_menu_item'>{t('Applications.myDeals')}</NavLink>
        </div>
      </div>
      <hr />
      <div className='content-wrapper'>
        <div className="PublicOffers-filter">
          <Input className='search_input' type="text" onChange={changeSearchValue} suffix={<SearchOutlined className='search_icon'/>} placeholder={t('Applications.search')}/>
          <div className='offers-filter'>
            <button onClick={filterClickHandlerDate} className="no-styles btn-text">{t('Applications.date')} <ArrowSVG
              style={{ transform: `rotate(${angalDate}deg)`, marginBottom: '3px', marginRight: '15px' }} width={10} /></button>

            <button onClick={filterClickHandler} className="no-styles btn-text">{t('Applications.price')} <ArrowSVG
              style={{ transform: `rotate(${angal}deg)`, marginBottom: '3px' }} width={10} /></button>

          </div>
        </div>
        <div className="Application_content">
          <Router history={history}>
            <Route exact path='/offers' component={PublicOffers} />
            <Route path='/offers/my' component={MyOffers} />
            <Route path='/trades' component={MyTrades} />
          </Router>
        </div>
      </div>
    </section>
  )
}

export default Offers
