import React, {useEffect, useState} from 'react';
import '../body.css'
import {useDispatch, useSelector} from "react-redux";
import {fetchNotPrivetOffers} from "../../../Home/homeSlice";
import {Tooltip} from "antd";
import Ruble from "../../../../assets/icon/₽.svg";
import ArrowSVG from "../../../../assets/icon/arrowLong.svg";
import {useTranslation} from "react-i18next";
import moment from "moment/moment";
import keycloak from "../../../../Keycloak";
import {Link} from "react-router-dom";
import styles from './styles.module.css';
import SecondaryTitle from '../../SecondaryTitle/SecondaryTitle';
import { login } from '../../../../utils/auth';

const NowOnPlatform = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const allOffers = useSelector(state => state.homeSlice.offers)
    const [randomOffer, setRandomOffer] = useState(null);
    const status = randomOffer?.status
    console.log(status)
    const [statusInfo, setStatusInfo] = useState({})
    console.log(statusInfo)
    const [btnInfo, setBtnInfo] = useState({})
    const formatDate = moment(randomOffer?.date).format('DD MMM YYYY')
    const isMine = randomOffer?.is_mine
    console.log(randomOffer)

    const getTitle = () => {
        if (randomOffer?.type === 'cargo') {
            return t('NewApplication.cargoTransportation')
        }
        if (randomOffer?.type === 'delivery') {
            return t('NewApplication.supplyOfGoods')
        }
        return t('NewApplication.transportationOfPassengers')
    }


    useEffect(() => {
        if (allOffers.length > 0) {
            const randomIndex = Math.floor(Math.random() * allOffers.length);
            setRandomOffer(allOffers[randomIndex]);
        }
    }, [allOffers]);

    useEffect(() => {
        dispatch(fetchNotPrivetOffers());
        if (status === 'disabled' && isMine) {
            setStatusInfo({
                class: "status-cancel",
                text: t('respond.notActive')
            })

            setBtnInfo({
                class: "btn red",
                text: t('respond.activate')
            })
        } else if (status === 'enabled') {
            setStatusInfo({
                class: "status-paid",
                text: t('Applications.waitingFor')
            })
            if (!isMine) {
                setBtnInfo({
                    class: "btn",
                    text: t('Applications.reply')
                })
            }
        }
        if (randomOffer?.tradeState === 'in_progress') {
            setStatusInfo({
                class: "status-in-processing",
                text: t('NewApplication.performed')
            })
            setBtnInfo({
                class: "btn",
                text: t('NewApplication.details')
            })
        }
        if (randomOffer?.tradeState === 'finished') {
            setStatusInfo({
                class: "status-success",
                text: t('respond.fulfilled')
            })
            setBtnInfo({
                class: "btn",
                text: t('NewApplication.details')
            })

        }

    }, [dispatch, isMine, status, t,]);
    return (
        <div className={styles.wrapper}>
            <SecondaryTitle>{t("Lending.nowOnPlatform")}</SecondaryTitle>
            {randomOffer
                ? <div onClick={() => login()} style={{cursor: 'pointer'}} className="card">
            <span className="card_header">
                <div style={{display: 'flex', alignItems: 'center'}} className="offer-number">№{randomOffer.id}
                    <div style={{marginLeft: '10px'}} className={`${statusInfo.class}`}>{statusInfo.text}</div>
                    {statusInfo.class === 'status-in-processin' || statusInfo.class === 'status-paid'
                        ? <Tooltip title={t('respond.rubleTooltip')}><img style={{marginLeft: '10px'}} src={Ruble}
                                                                          alt=""/></Tooltip>
                        : ''
                    }
                </div>
                <span style={{display: 'flex', alignItems: 'center',}} className="date">{formatDate}
                </span>
            </span>
                    <h2 className="card_title">{getTitle()}</h2>
                    <div className="card_footer">
                        <span className="card_info">
                            <span
                                className="card_info_item"><span>{t('Applications.route')}: </span>{randomOffer.upload.address.city ? randomOffer.upload.address.city : randomOffer.upload.address.state}<img
                                style={{margin: '0 2px', height: 19, width: 33}} src={ArrowSVG}
                                alt=""/>{randomOffer.unload.address.city ? randomOffer.unload.address.city : randomOffer.unload.address.state}</span>
                            <span className="card_info_item">
                                <span>{t('NewApplication.customerContacts')}: </span>
                                {randomOffer.user ? randomOffer.contact : <div style={{display: "flex"}}>
                                    <div className="blurred-content">Good boy, now authorize</div>
                                    <a className='show_requests' style={{
                                        color: '#6A62F4',
                                        fontWeight: '500',
                                        marginLeft: '10px',
                                        fontSize: '14px',
                                        textDecoration: 'underline'
                                    }}>{t("Lending.logInAndSee")}</a></div>}
                            </span>
                            {/*<span className="card_info_item"><span>{t('NewApplication.carrierContacts')}: </span> {randomOffer.contact}</span>*/}
                            {/*{randomOffer.role ? <span className="card_info_item"><span>{t('NewApplication.myRole')}: </span> {randomOffer.role}</span> : null}*/}
                            {/**/}
                            <span
                                className="card_info_item"><span>{t('NewApplication.price')}: </span> {randomOffer.price} ₽</span>
                        </span>
                        {/*<button onClick={activateOffer} style={{ marginTop: 20 }} className={btnInfo.class}>{btnInfo.text}</button>*/}
                    </div>
                    {/*<Modal closeIcon={<div className='close'>{t('NewApplication.close')}</div>} title={modalInfo.title} okText={t('Wallet.verify')} cancelText={t('NewApplication.rejection')}*/}
                    {/*       visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>*/}
                    {/*</Modal>*/}
                </div>
                : null
            }

            {/*{window.innerWidth > 750*/}
            {/*    ? <img className='offer_img' src={OfferImg} alt=""/>*/}
            {/*    : <img className='offer_img' src={OfferImgSmall} alt=""/>*/}
            {/*}*/}
            <div>
                <Link to={"/wallet"}>
                    <p className='show_requests'
                       style={{color: '#6A62F4', fontWeight: '500', textDecoration: 'underline'}}>
                        {t("Lending.viewAllApplications")}
                    </p>
                </Link>
            </div>
        </div>
    );
};

export default NowOnPlatform;
