import React from 'react';
import {ReactComponent as StepsCheckSVG} from "../../../assets/icon/steps/steps-check-green.svg";
import {ReactComponent as StepsFirstOrangeSVG} from "../../../assets/icon/steps/steps-first-orange.svg";
import {ReactComponent as StepsSecondGraySVG} from "../../../assets/icon/steps/steps-second-gray.svg";
import {ReactComponent as StepsSecondOrangeSVG} from "../../../assets/icon/steps/steps-second-orange.svg";
import {ReactComponent as StepsThirdGraySVG} from "../../../assets/icon/steps/steps-third-gray.svg";
import {ReactComponent as StepsFourthGraySVG} from "../../../assets/icon/steps/steps-fourth-gray.svg";
import {ReactComponent as StepsFourthOrangeSVG} from "../../../assets/icon/steps/steps-fourth-orange.svg";
import {ReactComponent as StepsFifthGraySVG} from "../../../assets/icon/steps/steps-fifth-gray.svg";
import {Tooltip} from "antd";
import {t} from "i18next";
import {useTranslation} from "react-i18next";

const StepsBar = ({status}) => {
    const { t } = useTranslation()
    return (
        <div className='status_container'>
            <div className='status_item'>
                <Tooltip placement="topRight" title={t('PublicOffer.generalRegulations.status_1')}>
                    {status === ''
                        ? <StepsFirstOrangeSVG />
                        : <StepsCheckSVG />
                    }
                </Tooltip>
            </div>
            <hr style={{width:'72px', marginRight:'16px', marginLeft:'16px'}}/>
            <div className='status_item'>
                <Tooltip title={t('PublicOffer.generalRegulations.status_2')}>
                    {status.class === 'created' || status === ''
                        ? <StepsSecondGraySVG/>
                        : status.class === 'status-paid'
                        ? <StepsSecondOrangeSVG/>
                        : <StepsCheckSVG/>
                    }

                </Tooltip>
            </div>
            <hr style={{width:'72px', marginRight:'16px', marginLeft:'16px'}}/>
            <div className='status_item'>
                <Tooltip title={t('PublicOffer.generalRegulations.status_3')}>
                    {status.class === 'created' || status.class === 'status-paid' || status === ''
                        ? <StepsThirdGraySVG/>
                        : <StepsCheckSVG/>
                    }
                </Tooltip>
            </div>
            <hr style={{width:'72px', marginRight:'16px', marginLeft:'16px'}}/>
            <div className='status_item'>
                <Tooltip title={t('PublicOffer.generalRegulations.status_4')}>
                    {status.class === 'created' || status.class === 'status-paid' || status === ''
                        ? <StepsFourthGraySVG/>
                        : status.class === 'status-in-processing'
                        ? <StepsFourthOrangeSVG/>
                        : <StepsCheckSVG/>
                    }
                </Tooltip>
            </div>
            <hr style={{width:'72px', marginRight:'16px', marginLeft:'16px'}}/>
            <div className='status_item'>
                <Tooltip placement="topRight" title={t('PublicOffer.generalRegulations.status_5')}>
                    {status.class === 'status-success'
                        ? <StepsCheckSVG/>
                        : <StepsFifthGraySVG/>
                    }
                </Tooltip>
            </div>
        </div>
    );
};

export default StepsBar;