import React from 'react';
import socialVkIcon from '../../../assets/newLanding/socials/socialVk.svg';
import socialOkIcon from '../../../assets/newLanding/socials/socialOk.svg';
import socialTgIcon from '../../../assets/newLanding/socials/socialTg.svg';
import shareEmailIcon from '../../../assets/newLanding/socials/shareEmail.svg';
import styles from './styles.module.css';
import { useTranslation } from 'react-i18next';
import { TelegramShareButton, OKShareButton, VKShareButton, EmailShareButton } from 'react-share';

const SocialItem = (props) => {
    const { iconSrc } = props;

    return (
        <div className={styles.socialItem}><img src={iconSrc} alt="" /></div>
    );
};

const Socials = () => {
    const { t } = useTranslation();
    const link = window.location.href;
    const message = t('Lending.shareText');

    return (
        <section className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                <h2 className={styles.title}>{t('Lending.invite')}</h2>
                <div className={styles.socialsList}>
                    <VKShareButton url={link} title={message}>
                        <SocialItem label="" iconSrc={socialVkIcon} />
                    </VKShareButton>
                    <OKShareButton url={link} title={message}>
                        <SocialItem label="" iconSrc={socialOkIcon} />
                    </OKShareButton>
                    <TelegramShareButton
                        url={link}
                        title={message}
                    >
                        <SocialItem label="" iconSrc={socialTgIcon} />
                    </TelegramShareButton>
                    <EmailShareButton url={link} title={message}>
                        <SocialItem label="" iconSrc={shareEmailIcon} />
                    </EmailShareButton>
                </div>
            </div>
        </section>
    )
};

export default Socials;
