import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import {ReactComponent as ArrowSVG} from '../../assets/icon/chatArrow.svg'
import './chats.css'
import GoBack from "../components/GoBack/GoBack";
import {useDispatch, useSelector} from "react-redux";
import {fetchChatMessages, fetchToken, fetchWhoAmI, markAsRead, sendMessage} from "./ChatSlice";
import moment from "moment";
import Loader from "../components/Loader";
import {useParams} from "react-router-dom";


function CurrentChat() {
    const id = window.location.href.split('/')[4];
    const {t} = useTranslation()
    const messageList = useSelector(state => state.chatSlice?.allMessages);
    const [messages, setMessages] = useState(messageList)
    const [event, setEvent] = useState('')
    const dispatch = useDispatch()
    const userID = useSelector(state => state.chatSlice?.userId)
    const [newMessage, setNewMessage] = useState('');
    const chatContainerRef = useRef(null);
    const inputRef = useRef(null);
    const keyPress = (e) => {
        if (e.key === 'Enter') {
            handleNewMessage()
        }
    }
    const handleNewMessage = () => {
        dispatch(sendMessage({ userID, recipientId: id, newMessage }))
            .then(() => {
                setNewMessage('');
                setMessages([...messages, { userId: userID, text: newMessage, createdAt: new Date() }])
            })
        inputRef.current.focus();
    };

    useLayoutEffect(() => {
        // Прокрутка вниз при первом рендеринге компонента или при изменении сообщений
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [messages]);

    useEffect(() => {
        const socket = new WebSocket(`wss://tonna.io/api/chat/${id}`)
        socket.addEventListener('message', e => {
            if (e.data) {
                console.log(e.data)
            }
        })
    },[])

    useEffect(() => {
        dispatch(fetchChatMessages({ userID, id })).then(() => {
            if (chatContainerRef.current) {
                chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
            }
        });
        dispatch(fetchWhoAmI());
        dispatch(markAsRead({recipientId: id, userID}))
    }, [dispatch, id, userID, messages,event]);


    return (
        <section className='Chats'>
            <div className='content-wrapper'>
                <GoBack prevPageName={t('Verification.goBack')}/>
                <h1 className='title'>{t('Lending.chat_detail')}</h1>
            </div>
            <div className='content-wrapper'>
                {/*{loading ? <Loader/> :*/}
                <div className='current_message_block' ref={chatContainerRef}>
                    {messageList.length <= 0
                        ? <div className='empty_message'>{t('Lending.noMessages')}</div>
                        : <div className='current_message_container' >
                            <div className='message_wrapper'>
                                {messageList?.map(message => (
                                    <div
                                        key={message.id}
                                        className={message.userId === id ? 'outgoing_message_container' : 'incoming_message_container'}>
                                        <div
                                            className={message.userId === id ? 'outgoing_message_body' : 'incoming_message_body'}>
                                            <span>{message.text}</span>
                                            <span
                                                className='message_time'>{moment(message.createdAt).format('hh:mm')}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    }

                </div>
                {/*}*/}
                <div className='message_input'>
                    <form>
                    </form>
                    <textarea onKeyPress={keyPress} autoFocus={true} value={newMessage} onChange={e => setNewMessage(e.target.value)}
                           style={{
                               outline: "none",
                               marginBottom: '20px',
                               height: '53px',
                               borderRadius: '50px',
                               resize: 'none',
                               paddingLeft: '20px',
                               position: 'relative',
                               fontSize: '16px',
                               paddingRight: '60px',
                               lineHeight: 1.5,
                               overflowY: 'hidden'
                           }} placeholder='Введите сообщение' className='input' type="text"  ref={inputRef}/>
                    <div onClick={handleNewMessage} style={{
                        width: '45px',
                        height: '45px',
                        position: "absolute",
                        right: '25px',
                        display: "flex",
                        alignItems: "center"
                    }}>
                        <ArrowSVG/>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CurrentChat;