import React, { useState } from 'react';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import tonnaRUVideo from '../../../assets/videos/tonnaRU.mp4';
import tonnaZNVideo from '../../../assets/videos/tonnaZN.mp4';
import tonnaENVideo from '../../../assets/videos/tonnaEN.mp4';
import playIcon from '../../../assets/newLanding/video/play.svg';
import styles from './styles.module.css';

const getVideoSrc = () => {
    console.log(i18n.language)
    if (i18n.language === 'en') {
        return tonnaENVideo;
    }
    if (i18n.language === 'zh') {
        return tonnaZNVideo;
    }
    return tonnaRUVideo;
};

const AboutVideo = () => {
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    return (
        <section className={styles.wrapper}>
            <SecondaryTitle>{t('Lending.howUseTonna')}</SecondaryTitle>
            {isOpened && <video src={getVideoSrc()} muted controls autoPlay />}
            {!isOpened && (
                <div
                    role="button"
                    aria-label="Play video"
                    className={styles.start}
                    onClick={() => setIsOpened(true)}
                >
                    <img src={playIcon} alt="" />
                </div>
            )}
        </section>
    );
};

export default AboutVideo;
