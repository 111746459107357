import React from 'react';
import { useTranslation } from 'react-i18next';
import keycloak from '../../../Keycloak';
import i18n from '../../../i18n';
import { Link } from 'react-router-dom';
import { downloadAppsLinks} from '../../../constants/downloadApps';
import tonnaLogoIcon from '../../../assets/newLanding/TonnaLogo.svg'
import appGalleryCircleIcon from '../../../assets/newLanding/AppGalleryCircleIcon.svg';
import appStoreCircleIcon from '../../../assets/newLanding/AppStoreCircleIcon.svg';
import googlePlayCircleIcon from '../../../assets/newLanding/GooglePlayCircleIcon.svg';
import ruStoreCircleIcon from '../../../assets/newLanding/RuStoreCircleIcon.svg';
import menuArrowIcon from '../../../assets/newLanding/menuArrowIcon.svg';
import skolkovoLogoIcon from '../../../assets/newLanding/footer/skolkovo.svg';
import styles from './styles.module.css';
import { login } from '../../../utils/auth';

const FooterMenuItem = (props) => {
    const { href, children } = props;

    return (
        <Link className={styles.menuItem} to={href}>
            {children}<img className={styles.menuItemIcon} src={menuArrowIcon} alt="" />
        </Link>
    );
};

const DownloadAppItem = (props) => {
    const { src, href, label } = props;

    return (
        <a className={styles.downloadAppItem} href={href} aria-label={label}>
            <img src={src} alt="" />
        </a>
    );
};

const getManualHref = () => {
    return i18n.language === 'ru'
        ? '/files/catalogue-template.csv' : i18n.language === 'en'
            ? "/files/catalogue-template-en.csv"
            : "/files/catalogue-template-zh.csv"
};

export const Footer = (props) => {
    const { withPromoBlock = false } = props;
    const { t } = useTranslation();

    return (
        <footer className={styles.wrapper}>
            <div className={styles.contentWrapper}>
                {withPromoBlock && (
                    <div className={styles.topSection}>
                        <h2 className={styles.title}>{t('Lending.expandYourBusiness')}</h2>
                        <button className={styles.startButton} onClick={() => login()}>{t('Lending.startGrowing')}</button>
                    </div>
                )}
                <div className={styles.bottomSection}>
                    <div className={styles.logo}>
                        <div className={styles.logoImage}><img src={tonnaLogoIcon} alt="" /></div>
                        <div className={styles.logoText}>{t('Lending.docSubTitle')}</div>
                    </div>
                    <div className={styles.menu}>
                        <div className={styles.menuSection}>
                            <FooterMenuItem href="/agreement">{t('Lending.userAgreement')}</FooterMenuItem>
                            <FooterMenuItem href="/offerta">{t('Lending.publicOffer')}</FooterMenuItem>
                            <FooterMenuItem href="/personalData">{t('Lending.personalData')}</FooterMenuItem>
                            <FooterMenuItem href="/frameworkContract">{t('Lending.frameworkContract')}</FooterMenuItem>
                            <FooterMenuItem href="/frameworkSupplyContract">{t('Lending.frameworkSupplyContract')}</FooterMenuItem>
                            <FooterMenuItem href="/plan">{t('Lending.strategy')}</FooterMenuItem>
                            <FooterMenuItem href="/files/rus_manual.pdf">{t('Lending.useManual')}</FooterMenuItem>
                            <FooterMenuItem href={getManualHref()}>{t('Lending.catalogue')}</FooterMenuItem>
                            <FooterMenuItem href="/sitemap">{t('Lending.siteMap')}</FooterMenuItem>
                        </div>
                    </div>
                    <div>
                        <div className={styles.downloadApp}>
                            <div className={styles.downloadAppText}>
                                {t('Lending.downloadApp')}
                            </div>
                            <div>
                                <DownloadAppItem src={ruStoreCircleIcon} href={downloadAppsLinks.ruStore} />
                                <DownloadAppItem src={appStoreCircleIcon} href={downloadAppsLinks.appStore} />
                                <DownloadAppItem src={googlePlayCircleIcon} href={downloadAppsLinks.googlePlay} />
                                <DownloadAppItem src={appGalleryCircleIcon} href={downloadAppsLinks.appGallery} />
                            </div>
                        </div>
                        <a className={styles.skolkovo} href="https://sk.ru/" target="_blank" aria-label="Skolkovo">
                            <img src={skolkovoLogoIcon} alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
