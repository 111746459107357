import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../../../Store/api'
import { message } from 'antd'
import { t } from 'i18next'

export const fetchOffer = createAsyncThunk(
  'offerSlice/fetchOffer',
  async ({ id }) => {
    const response = await api.get(`private/offers/${id}`)
    return response.data
  }
)
export const fetchTrade = createAsyncThunk(
  'offerSlice/fetchTrade',
  async ({ id }) => {
    const response = await api.get(`private/trade/${id}`)
    return response.data
  }
)
export const fetchRequests = createAsyncThunk(
  'offerSlice/fetchRequests',
  async ({ id }) => {
    const response = await api.get(`private/trade/requests/${id}`)
    return response.data
  }
)

export const enableOffer = createAsyncThunk(
  'offerSlice/enableOffer',
  async ({ id }) => {
    const response = await api.put(`private/offers/${id}/enable`, { id })
    return response.data
  }
)
export const disableOffer = createAsyncThunk(
  'offerSlice/disableOffer',
  async ({ id }) => {
    const response = await api.put(`private/offers/${id}/disable`, { id })
    return response.data
  }
)
export const startTrade = createAsyncThunk(
  'offerSlice/startTrade',
  async ({ offerId, requestId }) => {
    const data = {
      request_id: requestId / 1,
      offer_id: offerId / 1
    }
    const response = await api.post(`private/trade/create`, data)
    return response.data
  }
)

export const requestTrade = createAsyncThunk(
  'offerSlice/requestTrade',
  async ({ id, card, phoneNum, bik, bankName, invoiceNum, price, swift }) => {
    // const data = {
    //   card_number: card,
    //   offer_id: id / 1, Карта
    //   carrier_contact: phoneNum
    // }
    const data = {
      // card_number: 0,
      bank_account: invoiceNum,
      bank_bik: bik,
      bank_name: bankName,
      offer_id: id / 1,
      carrier_contact: phoneNum,
      price,
      swift,
      type: "bank"
    }
    const response = await api.post(`private/trade/request`, data)
    return response.data
  }
)
export const deleteRequestTrade = createAsyncThunk(
  'offerSlice/deleteRequestTrade',
  async ({ id }) => {
    const response = await api.delete(`private/trade/request/${id}`)
    return response.data
  }
)
export const finishTrade = createAsyncThunk(
  'offerSlice/finishTrade',
  async ({ id }) => {
    const data = {
      trade_id: id / 1
    }
    const response = await api.post(`private/trade/finish`, data)
    return response.data
  }
)
export const uploadFiles = createAsyncThunk(
  'offerSlice/uploadFiles',
  async ({ file }) => {
    const response = await api.post(`private/files`, file)
    return response.data
  }
)
export const getFiles = createAsyncThunk(
  'offerSlice/getFiles',
  async () => {
    const response = await api.get('private/files')
    return response.data
  }
)
const initialState = () => (
  {
    loaded: false,
    offerData: {},
    requests: [],
    files: [],
    error: {
      failed: false,
      message: null,
    }
  }
);

const offerSlice = createSlice({
  name: 'offerSlice',
  initialState: initialState(),
  reducers: {
    resetOffer: (state) => {
      state = initialState()
    }
  },
  extraReducers: {
    [fetchOffer.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [fetchOffer.fulfilled]: (state, action) => {
      state.loaded = true
      state.offerData = action.payload
    },
    [fetchOffer.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      state.error.message = action.payload;
    },
    [fetchRequests.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [fetchRequests.fulfilled]: (state, action) => {
      state.loaded = true
      state.requests = action.payload
    },
    [fetchRequests.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      state.error.message = action.payload;
    },
    [enableOffer.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [enableOffer.fulfilled]: (state, action) => {
      state.loaded = true
      message.success({
        content: 'Заявка активирована',
        style: {
          marginTop: '17vh'
        }
      })

    },
    [enableOffer.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      message.error({
        content: 'Вам не хватает средств на балансе...',
        style: {
          marginTop: '17vh'
        }
      })
      state.error.message = action.payload;
    },
    [disableOffer.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [disableOffer.fulfilled]: (state, action) => {
      state.loaded = true
      message.success({
        content: 'Заявка деактивирована',
        style: {
          marginTop: '17vh'
        }
      })
    },
    [disableOffer.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      // message.error({
      //   content: 'Что-то пошло не так...',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload;
    },
    [deleteRequestTrade.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [deleteRequestTrade.fulfilled]: (state, action) => {
      state.loaded = true
      message.success({
        content: t('supplierRejected'),
        style: {
          marginTop: '17vh'
        }
      })
    },
    [deleteRequestTrade.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      // message.error({
      //   content: 'Что-то пошло не так...',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload;
    },

    [fetchTrade.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [fetchTrade.fulfilled]: (state, action) => {
      state.loaded = true
      state.offerData = action.payload
    },
    [fetchTrade.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      state.error.message = action.payload;
    },
    [startTrade.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [startTrade.fulfilled]: (state, action) => {
      state.loaded = true
      message.success({
        content: t('respond.start'),
        style: {
          marginTop: '17vh'
        }
      })
    },
    [startTrade.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      // message.error({
      //   content: 'Что-то пошло не так...',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload;
    },
    [requestTrade.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [requestTrade.fulfilled]: (state, action) => {
      state.loaded = true
      message.success({
        content: 'Заявка отправлена',
        style: {
          marginTop: '17vh'
        }
      })

    },
    [requestTrade.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      message.error({
        content: 'Вы уже отправили заявку',
        style: {
          marginTop: '17vh'
        }
      })
      state.error.message = action.payload;
    },
    [finishTrade.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [finishTrade.fulfilled]: (state, action) => {
      state.loaded = true
      message.success({
        content: 'Заявка закрыта',
        style: {
          marginTop: '17vh'
        }
      })

    },
    [finishTrade.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      // message.error({
      //   content: 'Что-то пошло не так...',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload;
    },
    [uploadFiles.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [uploadFiles.fulfilled]: (state, action) => {
      state.loaded = true
      message.success({
        content: 'Файлы загружены',
        style: {
          marginTop: '17vh'
        }
      })

    },
    [uploadFiles.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      // message.error({
      //   content: 'Что-то пошло не так...',
      //   style: {
      //     marginTop: '17vh'
      //   }
      // })
      state.error.message = action.payload;
    },
    [getFiles.pending]: (state) => {
      state.loaded = false
      state.error.failed = false
      state.error.message = null
    },
    [getFiles.fulfilled]: (state, action) => {
      state.loaded = true
      state.files = action.payload
    },
    [getFiles.rejected]: (state, action) => {
      state.loaded = false
      state.error.failed = true;
      state.error.message = action.payload;
    },
  }
})
export const { resetOffer } = offerSlice.actions
export default offerSlice.reducer;
