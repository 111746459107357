import React, {useEffect} from 'react'
import { Form, Input, Select, DatePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeDate,
  changeNumber,
  changePrice,
  changeTerms,
  changeTransportation,
  newOfferSend
} from './newOfferSlice'
import { useTranslation } from 'react-i18next'
import locale from 'antd/es/date-picker/locale/ru_RU'
import history from '../../../utils/history'
import GoBack from '../../components/GoBack/GoBack'
import Loader from '../../components/Loader'
import selectLocationImg from '../../../assets/icon/selectLocation.svg'
import { priceMask, stringToNum } from '../../components/masks';
import moment from 'moment';
import GoTermsOfUse from '../../components/TermsOfUse/GoTermsOfUse';
import { fetchMyOffers } from '../offersSlice'
import StepsBar from "../components/StepsBar";
import dayjs from "dayjs";
import {Helmet} from "react-helmet";

function NewOffer() {
  const [form] = Form.useForm()
  const { Option } = Select
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const unload = useSelector(state => state.newOfferSlice.form.unload)
  const upload = useSelector(state => state.newOfferSlice.form.upload)
  const date = useSelector(state => state.newOfferSlice.form.date)
  const terms = useSelector(state => state.newOfferSlice.form.terms)
  const price = useSelector(state => state.newOfferSlice.form.price)
  const phoneNumber = useSelector(state => state.newOfferSlice.form.phoneNumber)
  const transportation = useSelector(state => state.newOfferSlice.form.transportation)
  const adding = useSelector(state => state.newOfferSlice.adding)
  console.log(transportation)

  const onReset = () => {
    form.resetFields()
  }

  const dateChange = (e) => {
    dispatch(changeDate(dayjs(e).valueOf()))
  }

  const termsChange = (e) => {
    dispatch(changeTerms(e.target.value))
  }

  const priceChange = (e) => {
    dispatch(changePrice(priceMask(e.target.value)))
  }

  const handleNumberChange = e => {
    dispatch(changeNumber(e.target.value))
  }

  const handleTransportationChange = value => {
    dispatch(changeTransportation(value))
  }

  function disabledDate(current) {
    return current && current < dayjs().subtract(1, 'days').endOf('day')
  }

  const goToMapPage = (type) => {
    return function pushState() {
      history.push({
        pathname: '/newoffer/selectlocation',
        state: {
          type: type
        }
      })
    }
  }

  const handleSubmit = () => {
    const form = {
      unload: {
        address: unload.name,
        x: unload.coords.lat,
        y: unload.coords.lng
      },
      upload: {
        address: upload.name,
        x: upload.coords.lat,
        y: upload.coords.lng
      },
      offer_type: transportation,
      contact: phoneNumber,
      date: dayjs(date).valueOf(),
      price: stringToNum(price),
      terms: terms
    }

      dispatch(
        newOfferSend({ form })
      ).then(() => {
        onReset()
        history.push('/offers/my')
      })
  }

  useEffect(() => {
    dispatch(fetchMyOffers());

  }, [dispatch])
  return (
    <div className="newRequest main">
      <div className="content-wrapper">
        <div >
          <Helmet>
            <title>{t('NewApplication.newApplication')}</title>
          </Helmet>
          <GoBack prevPageName={t('Verification.goBack')} />
          <h1 className="title">{t('NewApplication.newApplication')}</h1>
          <StepsBar status={''} />
          {adding ? <Loader /> :
            <Form className='new-req-form' onFinish={handleSubmit} form={form} size="large" name="Оставить заявку" initialValues={{}}
              scrollToFirstError>
              <Form.Item>
                <Input
                  required
                  suffix={<img src={selectLocationImg} alt="" />}
                  value={upload ? upload.name ? upload.name : ` ${upload.coords.lat}, ${upload.coords.lng}` : null} placeholder={t('NewApplication.loadingPoint')}
                  onClick={goToMapPage('upload')} />
              </Form.Item>
              <Form.Item>
                <DatePicker disabledDate={disabledDate}
                  format='DD.MM.YYYY'
                  locale={locale} required value={dayjs(date)} onChange={dateChange} placeholder={t('NewApplication.startDate')} />
              </Form.Item>
              <Form.Item>
                <Input
                  required
                  name='unload'
                  suffix={<img src={selectLocationImg} alt="" />}
                  value={unload ? unload.name ? unload.name : ` ${unload.coords.lat}, ${unload.coords.lng}` : null} onClick={goToMapPage('unload')}
                  placeholder={t('NewApplication.unloadingPoint')} />
              </Form.Item>
              <Form.Item>
                <Select placeholder={t('NewApplication.chooseType')} onChange={handleTransportationChange} value={transportation}>
                  <Option value='cargo'>{t('NewApplication.cargoTransportation')}</Option>
                  <Option value='passengers'>{t('NewApplication.transportationOfPassengers')}</Option>
                  <Option value='delivery'>{t('NewApplication.supplyOfGoods')}</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Input suffix='₽' required value={price} onChange={priceChange} placeholder={t('Applications.price')} />
              </Form.Item>
              <Form.Item>
                <Input required type='tel' value={phoneNumber} onChange={handleNumberChange} placeholder={t('NewApplication.customerContacts')} />
              </Form.Item>
              <Form.Item>
                <Input.TextArea value={terms} onChange={termsChange} rows="4" cols="50"
                  placeholder={t('NewApplication.additionalConditions')} />
              </Form.Item>
              <GoTermsOfUse btnName={t('NewApplication.send')} />
              <Form.Item className='new-req-form_btn-container'>
                <button className='btn' type="primary">{t('NewApplication.send')}</button>
              </Form.Item>
            </Form>}
        </div>
      </div>
    </div>
  )
}

export default NewOffer
