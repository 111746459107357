import React, { useEffect, useState } from 'react'
import { MapContainer, TileLayer, ZoomControl } from 'react-leaflet'
import marker from '../../../assets/icon/blueMarker.svg'
import carMark from '../../../assets/icon/carMark.svg'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet/dist/leaflet.css'
import { Form, Input, Modal, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchGeneralMapData,
  generalMapDataSend,
  setPlace
} from '../SelectLocationMap/components/selectLocationSlice'
import Loader from '../../components/Loader'
import Search from '../components/Search'
import Locate from './components/LocationMarker'
import PopupInfo from './components/PopupInfo'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import gift from '../../../assets/icon/gift.svg';
import ModalGift from './components/ModalGift/ModalGift';
import {Helmet} from "react-helmet";

function CargoMap() {
  const { t } = useTranslation()
  const urlParams = useParams()
  const id = urlParams.id
  const cords = urlParams.cords ? urlParams.cords.split(',') : null
  const [center, setCenter] = [{ lat: 55.7522, lng: 37.6156 }]
  const dispatch = useDispatch()
  const selectedPlace = useSelector(state => state.selectLocationSlice.selectedPlace.coords)
  const loading = useSelector(state => state.selectLocationSlice.loading)
  const places = useSelector(state => state.selectLocationSlice.cars)
  const [isModalVisible, setIsModalVisible] = useState(false)
  // const [isGiftActive, setIsGiftActive] = useState(true)
  const [phone, setPhone] = useState('')
  const [transport, setTransport] = useState('')
  const [terms, setTerms] = useState('')

  const phoneChange = (e) => {
    setPhone(e.target.value)
  }

  const transportChange = (e) => {
    setTransport(e.target.value)
  }

  const termsChange = e => {
    setTerms(e.target.value)
  }

  const carMarker = new L.icon({
    iconUrl: carMark,
    size: [60, 80],
    popupAnchor: [30, 0, 10, 10]
  })

  const customMarker = new L.icon({
    iconUrl: marker,
    size: [60, 80],
    popupAnchor: [21, 0, 10, 10]
  })

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
    const markedPlace = {
      upload: {
        x: selectedPlace.lat,
        y: selectedPlace.lng
      },
      transport: transport,
      contact: phone,
      terms: terms,
      date: moment().valueOf()
    }
    dispatch(generalMapDataSend({ markedPlace })).then((params) => {
      dispatch(fetchGeneralMapData())
    })
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    // в аргументах был токен
    dispatch(fetchGeneralMapData({  }))
  }, [dispatch])

  useEffect(() => {
    if (cords && id) {
      dispatch(setPlace(({ coords: { lat: cords[0], lng: cords[1] } })))
    }
  }, [])

  // function giftHandler() {
  //   setIsGiftActive(!isGiftActive);
  // }

  return (
      <div className="main">
        <div className="content-wrapper">
          <Helmet>
            <title>{t('Map.findTransport')}</title>
          </Helmet>
          <h1 className="title">{t('Map.findTransport')}</h1>
          <span className="serch-container">
          <Search selectedPlace={selectedPlace} />
        </span>
          <Modal style={{ zIndex: 1001 }} closeIcon={<div className="close">{t('NewApplication.close')}</div>}
                 title={t('Map.mark')}
                 visible={isModalVisible} onOk={handleOk}
                 onCancel={handleCancel}
                 footer={false}>
            <Form name='cargoOfferForm' onFinish={handleOk}>
              <Input suffix={<Tooltip title={t('Map.transportTypeTooltip')}><QuestionCircleOutlined /></Tooltip>} required style={{ height: '40px', marginBottom: '20px' }} placeholder={t('Map.description')}
                     onChange={transportChange} />
              <Input required style={{ height: '40px' }} placeholder={t('Map.phoneNumber')} onChange={phoneChange} />
              <Tooltip title={t('Map.otherDetailsTooltip')}>
                <Input.TextArea required style={{ marginTop: '20px' }} onChange={termsChange} rows="4" cols="50"
                                placeholder={t('Map.otherDetails')} />
              </Tooltip>
              <button style={{ marginTop: '32px' }} className='btn' htmlType="submit" type="primary">{t('Map.free')}</button>
            </Form>
          </Modal>
          <div className="map">
            {loading ? <Loader /> :
                <>
                  {/*<button disabled={isGiftActive} className='map-gift' onClick={giftHandler}>*/}
                  {/*  <img src={gift} alt="gift"/>*/}
                  {/*</button>*/}
                  {/*<ModalGift giftHandler={giftHandler} isGiftActive={isGiftActive} />*/}
                  <MapContainer
                      center={center}
                      zoom={13}
                      tap={false}
                      scrollWheelZoom={true}
                      zoomControl={false}>
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <ZoomControl position='bottomleft' />
                    {places ? places.map(place => {
                      return <PopupInfo urlParamsId={id} key={place.id} place={place} t={t} carMarker={carMarker} />
                    }) : null
                    }
                    <Locate selectedPlace={selectedPlace} customMarker={customMarker} showModal={showModal} t={t} />
                  </MapContainer>
                </>}
          </div>
        </div>
      </div>
  )
}

export default CargoMap