import React from 'react';
import { useTranslation } from 'react-i18next';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import otkritieLogoImage from '../../../assets/newLanding/partners/otkritie.svg'
import sberLogoImage from '../../../assets/newLanding/partners/sber.svg'
import tinkoffLogoImage from '../../../assets/newLanding/partners/tink.svg'
import tochkaLogoImage from '../../../assets/newLanding/partners/tochka.svg'
import rosTorgLogoImage from '../../../assets/newLanding/partners/rosTorg.svg'
import alfaLogoImage from '../../../assets/newLanding/partners/alfa.svg'
import styles from './styles.module.css';

const partnersLogoImages = [
    alfaLogoImage,
    sberLogoImage,
    tinkoffLogoImage,
    tochkaLogoImage,
    otkritieLogoImage,
    rosTorgLogoImage,
];

const Partners = () => {
    const { t } = useTranslation()

    const preparedPartnerImages = [...partnersLogoImages, ...partnersLogoImages];

    return (
        <section className={styles.wrapper}>
            <SecondaryTitle>{t('Lending.readinessTitle')}</SecondaryTitle>
            <div className={styles.list}>
                <div>
                    {preparedPartnerImages.map((imageSrc) => (
                        <img key={imageSrc} src={imageSrc} alt="" />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Partners;
