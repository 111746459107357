import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {api, whoAmIApi} from "../../Store/api";
import {chatApi} from "../../Store/api";
export const fetchToken = createAsyncThunk(
    'chats/fetchToken',
    async () => {
        const response = await api.get('/private/token')
        return response.data
    }
)

export const sendMessage = createAsyncThunk(
    'chats/sendMessage',
    async ({recipientId, userID, newMessage}) => {
        chatApi.defaults.headers = {
            'USER-ID': userID
        }
        const response = await chatApi.post(`/${recipientId}/message`, newMessage)
        return response.data
    }
)
export const fetchWhoAmI = createAsyncThunk(
    'chats/fetchToken',
    async () => {
        // api.defaults.headers = {
        //     Authorization: baseToken
        // }
        const response = await whoAmIApi.get('/private/whoami')
        return response.data
    }
)
export const markAsRead = createAsyncThunk(
    'chats/markAsRead',
    async ({recipientId, userID}) => {
        chatApi.defaults.headers = {
            'USER-ID': userID
        }
        const response = await chatApi.post(`/${recipientId}/mark-as-read`)
        return response.data
    }
)
export const fetchChats = createAsyncThunk(
    'chats/fetchChats',
    async ({userID}) => {
        chatApi.defaults.headers = {
            'USER-ID': userID
        }
        const response = await chatApi.get('/list',  )
        return response.data
    }
)

export const fetchChatMessages = createAsyncThunk(
    'chats/fetchChatMessages',
    async ({userID,id}) => {
        chatApi.defaults.headers = {
            'USER-ID': userID
        }
        const response = await chatApi.get(`/history/${id}`)
        return response.data
    }
)


const initialState = () => {
    return (
        {
            chatToken: '',
            loading: false,
            messagesLoading: false,
            chats: [],
            userId: null,
            allMessages: [],
            error: {
                failed: false,
                message: null
            }
        }
    )
}

const chatSlice = createSlice({
    name: 'chats',
    initialState: initialState(),

    extraReducers: {
        [fetchToken.pending]: (state) => {
            // state.loading = true
            state.error.failed = false
        },
        [fetchToken.fulfilled]: (state, action) => {
            // state.loading = false
            state.chatToken = action.payload
        },
        [fetchToken.rejected]: (state, action) => {
            // state.loading = false
            state.error.failed = true
            state.error.message = action.payload
        },
        [fetchChats.pending]: (state) => {
            state.loading = true
            state.error.failed = false
        },
        [fetchChats.fulfilled]: (state, action) => {
            state.loading = false
            state.chats = action.payload
        },
        [fetchChats.rejected]: (state, action) => {
            state.loading = false
            state.error.failed = true
            state.error.message = action.payload
        },
        [fetchChatMessages.pending]: (state) => {
            state.messagesLoading = true
            state.error.failed = false
        },
        [fetchChatMessages.fulfilled]: (state, action) => {
            state.messagesLoading = false
            state.allMessages = action.payload.reverse()
        },
        [fetchChatMessages.rejected]: (state, action) => {
            state.messagesLoading = false
            state.error.failed = true
            state.error.message = action.payload
        },
        [fetchWhoAmI.pending]: (state) => {
            state.error.failed = false
        },
        [fetchWhoAmI.fulfilled]: (state, action) => {
            // state.loading = false
            state.userId = action.payload.user_id
        },
        [fetchWhoAmI.rejected]: (state, action) => {
            // state.loading = false
            state.error.failed = true
            state.error.message = action.payload
        }
    }
})

export default chatSlice.reducer