import React from 'react'
import Start from "../Start/Start";
import {useTranslation} from "react-i18next";
import MoneyPig from '../../../../assets/icon/moneyPig.svg'
import CreateInvoice from '../../../../assets/icon/createInvoice.svg'
import CreateChat from '../../../../assets/icon/createChat.svg'
import Seo from '../../../../assets/icon/seo.svg'
import {NavLink} from "react-router-dom";
import BeneficiaryCard from "../BeneficiaryCard/BeneficiaryCard";

const SuccessPro=()=>{
    const {t} = useTranslation()

    return  <section className="Subscriptions main">
        <div className="content-wrapper left">
            <BeneficiaryCard/>
            <h1 className="success_title">{t('Subscriptions.paidSuccess')}  <span className="blue_title">PRO</span></h1>
            <div className='success_container'>
                <p className='success_container_title'>{t('Subscriptions.Success.title')}</p>
                <div className='success_container_item'>
                    <img src={MoneyPig} alt={''}/>
                    <span>{t('Subscriptions.Success.first')}</span>
                </div>
                <div className='success_container_item'>
                    <img src={CreateInvoice} alt={''}/>
                    <span>{t('Subscriptions.Success.second')}</span>
                </div>
                <div className='success_container_item'>
                    <img src={CreateChat} alt={''}/>
                    <span>{t('Subscriptions.Success.third')}</span>
                </div>
                <div className='success_container_item'>
                    <img src={Seo} alt={''}/>
                    <span>{t('Subscriptions.Success.fourth')}</span>
                </div>
            </div>
            <NavLink className='btn_return' to='/subscriptions'>{t('Subscriptions.Success.btn')}</NavLink>
        </div>
    </section>
}

export default SuccessPro