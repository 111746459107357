import React from 'react';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {useTranslation} from "react-i18next";
import {message, Modal} from 'antd'
import {t} from "i18next";
import {api} from '../../Store/api'

// const {t} = useTranslation()
export const fetchNews = createAsyncThunk(
    'news/fetchNews',
    async () => {
        const response = await api.get('public/news')
        return response.data
    }
)

export const sendBankRequest = createAsyncThunk(
    'home/sendBankRequest',
    async ({bank, contact, description}) => {
        const data = {
            bank: bank,
            description: description,
            contact: contact
        }
        const response = await api.post(`public/request_service`, data)
        return response.data
    }
)

export const fetchNotPrivetOffers = createAsyncThunk(
    'offersSlice/fetchPublicOffers',
    async () => {
        const response = await api.get('public/offers');
        return response.data;
    },
)


const initialState = () => {
    return (
        {
            news: [],
            loading: false,
            offers: [],
            error: {
                failed: false,
                message: null
            }
        }
    )
}

const homeSlice = createSlice({
    name: 'home',
    initialState: initialState(),

    extraReducers: {
        [fetchNews.pending]: (state) => {
            state.loading = true
            state.error.failed = false
        },
        [fetchNews.fulfilled]: (state, action) => {
            state.loading = false
            state.news = action.payload
        },
        [fetchNews.rejected]: (state, action) => {
            state.loading = false
            state.error.failed = true
            state.error.message = action.payload
        },
        [fetchNotPrivetOffers.pending]: (state) => {
            state.loading = true
            state.error.failed = false
        },
        [fetchNotPrivetOffers.fulfilled]: (state, action) => {
            state.loading = false
            state.offers = action.payload
        },
        [fetchNotPrivetOffers.rejected]: (state, action) => {
            state.loading = false
            state.error.failed = true
            state.error.message = action.payload
        },
        [sendBankRequest.pending]: (state) => {
            state.reqSending = true
        },
        [sendBankRequest.fulfilled]: () => {
            Modal.success({
                title: t('Lending.modalSendThanks'),
                content: t('Lending.modalSendThanksText'),
                width: 320,
                // footer: <div class='ant-modal-footer'><button className='button'>ОК</button></div>,
                closable: true,
                onOk() {
                },
            });
        },
        [sendBankRequest.rejected]: () => {
            Modal.error({
                title: t('Lending.modalSendSorry'),
                content: t('Lending.modalSendSorryText'),
                width: 320,
                // footer: <div class='ant-modal-footer'><button className='button'>ОК</button></div>
            });
        },
    }
})

export default homeSlice.reducer
