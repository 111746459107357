import React from 'react'
import GoBack from '../GoBack/GoBack'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {Helmet} from "react-helmet";

function TermsOfUse(props) {
  const { t } = useTranslation()
  const history = useHistory()
  return (
    <section>
      <div className="content-wrapper">
        <GoBack prevPageName={t('Verification.goBack')} />
        <Helmet>
          <title>{t('TermsOfUse.termsOfUse')}</title>
        </Helmet>
        <h1 style={{ marginBottom: 5 }} className="title">{t('TermsOfUse.termsOfUse')}</h1>
        <div className="offer_body">
          <div style={{ maxWidth: '400px', display: 'flex', flexDirection: 'column' }}>
            <div className="offer_body_item" style={{ margin: '26px 0 15px' }}>{t('TermsOfUse.generalConditions')}</div>
            <div className='terms_item'>{t('TermsOfUse.generalConditionsText')}</div>
            <div className='offer_body_item' style={{ margin: '16px 0 15px' }}>{t('TermsOfUse.placementOfApplication')}</div>
            <div className='terms_item'> {t('TermsOfUse.placementOfApplicationText')}</div>
            <div className='offer_body_item' style={{ margin: '16px 0 15px' }}>{t('TermsOfUse.transactions')}</div>
            <div className='terms_item'>{t('TermsOfUse.transitionsText')}</div>
          </div>
          <div style={{ marginTop: 40 }} className="button_container">
            <button onClick={() => history.goBack()} className="btn">{t('TermsOfUse.acceptButton')}</button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TermsOfUse
