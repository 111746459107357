import React from 'react';
import GoBack from "./components/GoBack/GoBack";
import {useTranslation} from "react-i18next";
import i18n from "../i18n";
import {ReactComponent as ArrowSVG} from "../assets/icon/arrowRight.svg";
import {Helmet} from "react-helmet";

const SiteMap = () => {
    const {t} = useTranslation()
    return (
        <section>
            <div className="content-wrapper">
                <GoBack prevPageName={t('Verification.goBack')}/>
                <Helmet>
                    <title>{t('Lending.siteMap')}</title>
                </Helmet>
                <h1 style={{marginBottom: 20}} className="title">{t('Lending.siteMap')}</h1>
                <div className='site_map-container'>
                    <div className='site_map-elements'>
                        <span className='site_map-subtitle'>{t('Lending.profile')}</span>
                        <a href='/offers' className='site_map-sub_elements'>{t('Menu.requests')}</a>
                        <a href='/newoffer' className='site_map-sub_elements'>{t('NewApplication.newApplication')}</a>
                        <a href='/subscriptions' className='site_map-sub_elements'>{t('Menu.subscriptions')}</a>
                        <a href='/wallet' className='site_map-sub_elements'>{t('Menu.wallet')}</a>
                    </div>
                    <div className='site_map-elements'>
                        <span className='site_map-subtitle'>{t('Lending.userDocs')}</span>
                        <a href='/agreement' className='site_map-sub_elements'>{t('Lending.userAgreement')}</a>
                        <a href='/personalData' className='site_map-sub_elements'>{t('Lending.personalData')}</a>
                        <a href='/offerta' className='site_map-sub_elements'>{t('Lending.publicOffer')}</a>
                        {i18n.language === 'ru'
                            ? <span><a target='_blank' href="/files/rus_manual.pdf" className='site_map-sub_elements'>{t('Lending.useManual')} (PDF) <ArrowSVG /></a></span>
                            : <span></span>
                        }
                    </div>
                    <div className='site_map-elements'>
                        <span className='site_map-subtitle'>{t('Lending.frameworkContracts')}</span>
                        <a href='/frameworkContract' className='site_map-sub_elements'>{t('Lending.frameworkContract')}</a>
                        <a href='/frameworkSupplyContract' className='site_map-sub_elements'>{t('Lending.frameworkSupplyContract')}</a>
                    </div>
                    <div className='site_map-elements'>
                        <a href='https://partners.tonna.io' className='site_map-subtitle'>{t('Menu.partners')}</a>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default SiteMap;