import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import './chats.css';
import { useSelector, useDispatch } from "react-redux";
import { fetchChats, fetchToken, fetchWhoAmI, setLoading } from "./ChatSlice";
import history from "../../utils/history";
import Loader from "../components/Loader";
import { Helmet } from "react-helmet";
import { getConfig } from "../../configs/config";

function Chats(props) {
    const { t } = useTranslation();
    const loading = useSelector(state => state.chatSlice?.loading)
    const chatsList = useSelector(state => state.chatSlice?.chats)
    const token = useSelector(state => state.chatSlice?.chatToken?.access_token)
    const userID = useSelector(state => state.chatSlice?.userId)

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchWhoAmI());
        // dispatch(fetchToken());
        dispatch(fetchChats({ userID: userID }));
    }, [dispatch, userID]);

    const handleChatClick = (chatUserId) => {
        history.push(`/chat/${chatUserId}`);
    };

    return (
        <section className='Chats main'>
            <div className='content-wrapper'>
                <Helmet>
                    <title>{t('Lending.chat')}</title>
                </Helmet>
                <div style={{ display: 'flex' }}>
                    <h1 className='title'>{t('Lending.chat')}</h1>
                </div>
            </div>
            <div className='content-wrapper'>
                {chatsList.length <= 0
                ? <div className='empty_message'>{t('Lending.noChats')}</div>
                : loading ? <Loader/> :
                            chatsList.map((chat) => (
                                <div className='chat-wrapper' key={chat?.id} onClick={() => handleChatClick(chat.withUser)}>
                                    {/*<div className='avatar'>{'#' + chat?.userId.slice(0, 3)}</div>*/}
                                    <div className='avatar'>{'#' + chat?.withUser}</div>
                                    <div className='message_block'>
                                        <div className='message_title'>
                                            <span className='message_header'>{chat?.withUser}</span>
                                            <div>
                                                <span>{chat.date}</span>
                                            </div>
                                        </div>
                                        <div>
                                            <span>{chat.lastMsg}</span>
                                            {!chat.wasRead
                                                ? <span className='count_circle'></span>
                                                : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))
                    }


            </div>
        </section>
    );
}

export default Chats;