import React, { useEffect, useRef, useState } from 'react';

import arrowIcon from '../../../../assets/newLanding/header/arrow.svg';
import styles from './styles.module.css';
import {useTranslation} from "react-i18next";

const langs = [
    { label: 'Ru', value: 'ru' },
    { label: 'En', value: 'en' },
    { label: '中', value: 'zh' },
];

const LangSelect = () => {
    const {i18n} = useTranslation()
    const {t} = useTranslation()
    const [isOpened, setIsOpened] = useState(false);
    const optionsWrapperRef = useRef(null);
    const currentValue = i18n.language ?? 'ru';
    const currentLang = langs.find((lang) => currentValue === lang.value);
    const langOptions = langs.filter((lang) => currentValue !== lang.value);

    useEffect(() => {
        const handleEscKey = (event) => {
            if (event.key === 'Escape') {
                setIsOpened(false);
            }
        };

        const handleClick = (event) => {
            if (optionsWrapperRef.current && event.target && !optionsWrapperRef.current.contains(event.target)) {
                setIsOpened(false);
            }
        };

        window.addEventListener('keydown', handleEscKey);
        window.addEventListener('click', handleClick);
        return () => {
            window.removeEventListener('keydown', handleEscKey);
            window.removeEventListener('click', handleClick);
        };
    }, []);

    const selectLang = (value) => {
        i18n.changeLanguage(value ?? 'ru');
        setIsOpened(false);
    };

    const handleControlClick = (event) => {
        event.stopPropagation();
        setIsOpened((prev) => !prev);
    };

    const lang = window.navigator.language

    useEffect(() => {
        document.title = t('docTitle')
        if (lang === "en-US") {
            i18n.changeLanguage('en')
        }
        else if (lang === "ru-RU") {
            i18n.changeLanguage('ru')
        }
        else if (lang === "zh-CH") {
            i18n.changeLanguage('zh')
        }
    }, [lang])

    return (
        <div className={styles.wrapper}>
            <button className={styles.control} onClick={handleControlClick}>
                <div className={styles.controlValue}>{currentLang.label}</div>
                <img className={styles.controlArrowIcon} src={arrowIcon} alt="" />
            </button>
            {isOpened && (
                <div className={styles.options} ref={optionsWrapperRef}>
                    {langOptions.map((lang) => (
                        <button className={styles.optionItem} onClick={() => selectLang(lang.value)}>
                            {lang.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LangSelect;
