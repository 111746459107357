import React from 'react';
import Footer from "../components/Footer/Footer";
import Partners from './Partners/Partners';
import Socials from './Socials/Socials';
import Rates from './Rates/Rates';
import Hero from './Hero/Hero';
import AboutVideo from './AboutVideo/AboutVideo';
import Questions from './Content/Questions/Questions';
import NowOnPlatform from './Content/NowOnPlatform/NowOnPlatform';
import Cards from './Cards/Cards';
import './landing.css';


function Landing() {
    return (
        <div className='home'>
            <Hero />
            <AboutVideo />
            <Partners />
            <Cards />
            <Socials />
            <Rates />
            <Questions />
            <NowOnPlatform />
            <Footer withPromoBlock />
        </div>
    );
}

export default Landing;